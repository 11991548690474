
import { JCCDepositObject } from '../_models';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class JCCStatementService {
  private _Url: string = "/api/jccstatement/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  getAll() {
    return this.authHttp.get<any>(this._Url + "ALL" + "/");
  }

  getByID(aJCCStatementID: number) {
    return this.authHttp.get<any>(this._Url + "ID" + "/" + aJCCStatementID + "/");
  }

  getActive() {
    return this.authHttp.get<any>(this._Url + "ACT" + "/");
  }
  getAllPending() {
    return this.authHttp.get<any>(this._Url + "PEN" + "/");
  }

  importStatementsBankCharges() {
    return this.authHttp.get<any>(this._Url + "JCCStatementsBankCharges" + "/");
  }

  getStatementFiles() {
    return this.authHttp.get<any>(this._Url + "JCCStatementsGetLocationFiles" + "/");
  }

  paymentTransactionImporting(bank:string,bankStatement: object[]) {
    //console.log("FROM SERVICE ",bankStatement);
    return this.authHttp.post<any>(this._Url + "PaymentTransactionImporting/"+bank+"/",bankStatement);

  }
  validatePolicies(bankStatement: object) {
    //console.log("FROM SERVICE ",bankStatement);
    return this.authHttp.post<any>(this._Url + "ValidatePolicies"+"/",bankStatement);

  }
  importExcelData(selectedBank,excelData) {
    return this.authHttp.post<any>(this._Url + "ImportExcelData" + "/"+selectedBank + "/", excelData);
  }
  getPayments() {
    return this.authHttp.get<any>(this._Url + "GetAllPayments");
  }
}
