//import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDialogModule } from '@angular/material';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { VersionModalComponent } from '../../pages/versionmodal/versionmodal.component';


@NgModule({
  imports: [MatDialogModule, CommonModule ],
  declarations: [ VersionModalComponent],
  entryComponents: [VersionModalComponent],
  providers: [DatePipe],
  exports: [VersionModalComponent]
})
export class VersionModalModule {
}
