<style>

  .LanguageBtn {
    color: Highlight;
    font-style: italic;
    cursor: pointer;
  }

    .LanguageBtn:hover {
      text-decoration: underline;
    }

  .SelectedLanguageBtn {
    color: orange !important;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
  }

    .SelectedLanguageBtn:hover {
      text-decoration: underline;
    }

  .custom-login {
    display: none;
    list-style-type: none;
  }

  @media screen and (max-width:991px) {
    .custom-login {
      display: block;
      /*justify-content: center;*/
    }
  }
</style>


<div *ngIf="this.authService.isLoggedIn && this.usrInfo.isLoggedInDataComplete && updateMsg != ''" style="padding-bottom:40px;"></div>


<nav id="navbar" class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">

  <div class="row" style="width:99%">
    <div class="col-md-12">

      <div style="padding-left:5px; " *ngIf="this.authService.isLoggedIn && this.usrInfo.isLoggedInDataComplete && updateMsg != ''">
        <div class="updateMsg" style="padding:5px; color:red">
          <span>{{updateMsg}}</span>
        </div>
      </div>
    </div>

    <div class="col-md-12" style="margin-right:0;padding-right:0">

      <div class="container-fluid" style="margin-right:0;padding-right:0;width:100% !important">
        <div class="navbar-wrapper" style="margin-right:0;padding-right:0;">
          <div class="navbar-toggle">
            <button type="button" style=" display: block; margin-left: auto; margin-right: 0;" class="navbar-toggler" (click)="sidebarToggle()">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
          </div>

          <!--<a class="navbar-brand" (click)="this.router.navigate(['dashboard']);" href="javascript:void(0)">{{getTitle()}}</a>-->


          <p [ngStyle]="{'color' : DBName=='YDROTEST' || DBName=='YDROTST2'  ? 'purple' : ''}" style="white-space:normal; overflow-wrap:break-word;" class="navbar-brand">{{getTitle()}}</p>

          <button *ngIf="this.authService.isLoggedIn" class="navbar-toggler navbar-custom-menu-button" type="button" (click)="collapse()"
                  [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
          </button>

          <div style="padding-left:10px; font-size:18px;">
            <span [ngClass]="{'SelectedLanguageBtn': this.genericService.translate.currentLang === 'en'}" class="LanguageBtn" (click)="changeLanguage('en')">English</span>
            /
            <span [ngClass]="{'SelectedLanguageBtn': this.genericService.translate.currentLang === 'el'}" class="LanguageBtn" (click)="changeLanguage('el')">Ελληνικά</span>
          </div>

        </div>
        
          <div class="custom-login mr-3">
            <li class="nav-item" *ngIf="!this.authService.isLoggedIn">
              <a class="nav-link btn-rotate" [routerLink]="['/login']">
                <div class="d-flex align-items-center">
                  <i class="nc-icon material-icons-outlined md-16">lock_open</i>
                  <p> {{'Sign In' | translate}}</p>
                </div>
              </a>
            </li>
            <!--<li class="nav-item" *ngIf="this.authService.isLoggedIn">
              <a class="nav-link btn-rotate" routerLink="" (click)="logout(); closePanel()">
                <div class="d-flex align-items-center">
                  <i class="nc-icon material-icons-outlined md-16">lock</i>
                  <p>{{'Sign out' | translate}}</p>
                </div>
                  </a>
            </li>-->
          </div>
          
            <div  class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">

              <span (click)="showVersionModal()" class="VersionModalLink" *ngIf="this.authService.isLoggedIn && this.usrInfo.isLoggedInDataComplete && this.version != ''">
                <b>{{'Version' | translate}} : {{this.version}}     </b>
              </span>

              <form>
                <div class="input-group no-border" *ngIf="this.authService.isLoggedIn  && this.usrInfo.isLoggedInDataComplete" [ngStyle]="{'background-color': this.isImpersonating?'lightcoral':''}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <span class="nc-icon nc-single-02"></span>
                    </span>
                  </div>
                  <input matInput
                         class="form-control"
                         placeholder="Select Impersonation"
                         [formControl]="impersonationControl"
                         [matAutocomplete]="impersonation" />
                  <mat-autocomplete [displayWith]="displayFU"
                                    #impersonation="matAutocomplete"
                                    (optionSelected)="impersonationSelected($event.option.value)">
                    <mat-option *ngFor="let option of filteredUsersImpersonations | async" [value]="option">
                      {{option.Username}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </form>
              <ul class="navbar-nav" *ngIf="this.currentPageName != '/sms-gdpr'">

                <li class="nav-item" *ngIf="this.authService.isLoggedIn && this.usrInfo.isLoggedInDataComplete && showHelpButton">
                  <a class="nav-link btn-magnify" data-toggle="tooltip" title="Help" [routerLink]="" (click)="help(); closePanel()">
                    <i class="nc-icon nc-alert-circle-i"></i> {{'Help' | translate}}
                    <!--<p>
            <span class="d-lg-none d-md-block">Help</span>
          </p>-->
                  </a>
                </li>

                <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left" *ngIf="this.authService.isLoggedIn && this.usrInfo.isLoggedInDataComplete && (this.usrInfo.isAdministrator)">
                  <!-- || this.allowView_AdminTools-->
                  <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
                    <i class="nc-icon nc-settings-gear-65"></i>
                    <p>
                      <span class="d-lg-none d-md-block">Actions</span>
                    </p>
                  </a>
                  <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/userlist']" (click)="closePanel()">Users</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/branchlist']" (click)="closePanel()">Branches</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/grouplist']" (click)="closePanel()">Groups</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/usergrouplist']" (click)="closePanel()">User Groups</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/modulelist']" (click)="closePanel()">Modules</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/modulesaccesslist']" (click)="closePanel()">Modules Access</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/covernotesmodulelist']" (click)="closePanel()">Cover Notes Validations</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/covernotesmodulesaccesslist']" (click)="closePanel()">Cover Notes Validations Access</a>
                    <!--<a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/covernotesapprovals']" (click)="closePanel()">Cover Notes Approvals</a>-->
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/emaildistributionlist']" (click)="closePanel()">Email Distribution</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/reportrecreationlist']" (click)="closePanel()">Report Recreation</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/receiptsprint']" (click)="closePanel()">Receipts Print</a>
                    <a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" (click)="runUpdateDailyReport(); closePanel()">Update Receipts Not Deposited</a>


                    <!--<a ngbDropdownItem *ngIf="this.usrInfo.isAdministrator" [routerLink]="['/updatesynthesisregnumber']"(click)="closePanel()">Update Synthesis Registration Number</a>-->
                    <!--<a ngbDropdownItem (click)="updateSaltForAllUsers(); closePanel()">Update Salt For All Users</a>-->
                    <!--<a ngbDropdownItem (click)="test()">Another action</a>-->
                    <!--<a ngbDropdownItem href="javascript:void(0)">Something else here</a>-->
                  </div>
                </li>
                <li class="nav-item" *ngIf="!this.authService.isLoggedIn">
                  <a class="nav-link btn-rotate" [routerLink]="['/login']">
                    <i class="nc-icon material-icons-outlined md-16">lock_open</i>{{'Sign In' | translate}}
                  </a>
                </li>
                <li class="nav-item" *ngIf="this.authService.isLoggedIn">
                  <a class="nav-link btn-rotate" routerLink="" (click)="logout(); closePanel()">
                    <i class="nc-icon material-icons-outlined md-16">lock</i>{{'Sign out' | translate}}
                  </a>
                </li>
              </ul>
            </div>
         
        </div>
      </div>
  </div>
</nav>
