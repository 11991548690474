import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from ".";
import { MessageData } from "../_models/messagedata";

@Injectable()
export class CommunicationService {
  private _Url: string = "/api/communication/";
  constructor(private authHttp: HttpClient) {
  }
  sendSingleEmail(messageData: MessageData) {
    return this.authHttp.post<any>(this._Url + "SendSingleEmail" + "/", messageData)
  }
  sendSingleSMS(messageData: MessageData) {
    return this.authHttp.post<any>(this._Url + "SendSingleSMS" + "/", messageData)
  }
  getUserCommunicationTemplates(runCode: string, userID: string) {
    return this.authHttp.get<any>(this._Url + "GetUserCommunicationTemplates" + "/" + runCode + "/" + userID);
  }
  getCommunicationTemplateUsers(templateID: string) {
    return this.authHttp.get<any>(this._Url + "GetCommunicationTemplateUsers" + "/" + templateID);
  }
  saveCommunicationTemplate(template: object, method: string, templateID: number) {
    return this.authHttp.post<any>(this._Url + "SaveCommunicationTemplate/" + method + "/" + templateID + "/", template);
  }
  saveTemplatePermission(permission: object) {
    return this.authHttp.post<any>(this._Url + "SaveTemplatePermission", permission);
  }
  deleteTemplatePermission(permissionID: number) {
    return this.authHttp.get<any>(this._Url + "DeleteTemplatePermission/" + permissionID);
  }


}


