
import { RecurrentTaskObject } from '../_models/recurrent_task';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class RecurrentTaskService {
  private _Url: string = "/api/recurrentTask/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "recurrentTask";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aRecurrentTaskID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aRecurrentTaskID + "/");
  }


  add(aRecurrentTask: RecurrentTaskObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aRecurrentTask);
  }

  update(aRecurrentTaskID: number, aRecurrentTask: RecurrentTaskObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aRecurrentTaskID + "/", aRecurrentTask);
  }

  delete(aRecurrentTaskID: number) {
    let aRecurrentTask: RecurrentTaskObject = new RecurrentTaskObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aRecurrentTaskID + "/", aRecurrentTask)
  }

  //unique
  getAllEnabled() {
    return this.authHttp.get<any>(this._Url + "GetAllEnabled" + "/");
  }

  updateIsRunningByTaskName(name: string, isRunning: boolean) {
    return this.authHttp.get<any>(this._Url + "UpdateIsRunningByTaskName/" + name + "/" + isRunning + "/");
  }


}
