import { Injectable, ErrorHandler, Injector, NgZone, Component, ViewChild, OnInit, Input } from '@angular/core';
import { PdfViewerComponent, PDFDocumentProxy, PDFSource, PDFProgressData } from 'ng2-pdf-viewer';
import { HexBase64BinaryEncoding } from 'crypto';

import { NotificationsService, ToasterType, ToasterPosition } from '../../_services';

import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

declare interface TableData {
  headerRow: string[];
  dataRows: string[][];
}

@Component({
  selector: 'helppdfcustomviewer',
  moduleId: module.id,
  templateUrl: 'helppdfcustomviewer.component.html',
  styleUrls: ['./helppdfcustomviewer.component.css']
})

export class HelpPDFCustomViewerComponent implements OnInit {

  @Input() pdfDisplay;

  @Input() showEmailIcon = false;
  @Input() clientEmail = '';
  @Input() agentEmail = '';

  //pdfviewer 
  pdfSrc: string | PDFSource | ArrayBuffer | HexBase64BinaryEncoding = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  progressData: PDFProgressData;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = false;
  outline: any[];
  isOutlineShown = false;
  pdfQuery = '';

  @ViewChild(PdfViewerComponent, { static: false }) private pdfComponent: PdfViewerComponent;


  constructor(private notificationService: NotificationsService) {
  }

  ngOnInit() {

  }


  sendEmail() {
    if (this.pdf == undefined || this.pdf == null) {
      return;
    }

    window.location.href = "mailto:c.vikentiou@ypera.com.cy?subject=files&body=test&attachment=C:\\TestingBi.pdf";


  }

  download() {
    if (this.pdf == undefined || this.pdf == null) {
      return;
    }

    this.pdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf'
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE11 and Edge
        window.navigator.msSaveOrOpenBlob(blob, 'Report.pdf');
      } else {
        // Chrome, Safari, Firefox, Opera
        let url = URL.createObjectURL(blob);
        this.openLink(url);
        // Remove the link when done
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 5000);
      }
    });
  }

  private openLink(url: string) {
    let a = document.createElement('a');
    // Firefox requires the link to be in the body
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = 'Report.pdf';
    a.click();
    // Remove the link when done

    setTimeout(function () {
      document.body.removeChild(a);
    }, 5000);
  }

  printPdf() {
    if (this.pdf == undefined || this.pdf == null) {
      return;
    }
    this.pdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf'
      });

      const printFile = URL.createObjectURL(blob);
      //window.open(printFile).print();

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = printFile;
      document.body.appendChild(iframe);
      setTimeout(function () {
        iframe.contentWindow.print();
      }, 5000);

    });

   
  }

  viewExternalPdf() {
    if (this.pdf == undefined || this.pdf == null) {
      return;
    }
    this.pdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf'
      });

      const printFile = URL.createObjectURL(blob);
      window.open(printFile);

    });


  }


  openLocalFile() {
    jQuery('#file').trigger('click');
  }

  toggleOutline() {
    this.isOutlineShown = !this.isOutlineShown;
  }

  incrementPage(amount: number) {
    if (this.pdf == undefined || this.pdf == null) {
      return;
    }
    if (this.page + amount >= 1 && this.page + amount <= this.pdf.numPages) {
      this.page += amount;
    }
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  rotate(angle: number) {
    this.rotation += angle;
  }

  /**
 * Render PDF preview on selecting file
 */
  onFileSelected() {
    const $pdf: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      };

      reader.readAsArrayBuffer($pdf.files[0]);
    }
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf
   */
  afterLoadComplete(pdf: PDFDocumentProxy) {

      this.pdf = pdf;
      this.isLoaded = true;
      this.loadOutline();

  }

  /**
 * Get outline
 */
  loadOutline() {
    if (this.pdf == undefined || this.pdf == null) {
      return;
    }
    this.pdf.getOutline().then((outline: any[]) => {
      this.outline = outline;
    });
  }

  /**
 * Handle error callback
 *
 * @param error
 */
  onError(error: any) {
    
    this.error = error; // set error
    this.notificationService.show(ToasterType.error, error, ToasterPosition.topCenter, 4000);
  }

  setPassword(password: string) {
    let newSrc;
    if (this.pdfSrc instanceof ArrayBuffer) {
      newSrc = { data: this.pdfSrc };
    } else if (typeof this.pdfSrc === 'string') {
      newSrc = { url: this.pdfSrc };
    } else {
      newSrc = { ...this.pdfSrc };
    }
    newSrc.password = password;
    this.pdfSrc = newSrc;
  }

  /**
 * Pdf loading progress callback
 * @param {PDFProgressData} progressData
 */
  onProgress(progressData: PDFProgressData) {
    this.progressData = progressData;
    this.isLoaded = false;
    this.error = null; // clear error
  }

  getInt(value: number): number {
    return Math.round(value);
  }

  /**
 * Navigate to destination
 * @param destination
 */
  navigateTo(destination: any) {
    this.pdfComponent.pdfLinkService.navigateTo(destination);
  }

  /**
   * Scroll view
   */
  scrollToPage() {
    this.pdfComponent.pdfViewer.scrollPageIntoView({
      pageNumber: 3,
    });
  }

  /**
  * Page rendered callback, which is called when a page is rendered (called multiple times)
  *
  * @param {CustomEvent} e
  */
  pageRendered(e: CustomEvent) {
    //console.log('(page-rendered)', e);
  }

  searchQueryChanged(newQuery: string) {
    if (newQuery !== this.pdfQuery) {
      this.pdfQuery = newQuery;
      this.pdfComponent.pdfFindController.executeCommand('find', {
        query: this.pdfQuery,
        highlightAll: true,
      });
    } else {
      this.pdfComponent.pdfFindController.executeCommand('findagain', {
        query: this.pdfQuery,
        highlightAll: true,
      });
    }
  }



}
