

<div class="pdf-whole-container">
  <div>
    <input (change)="onFileSelected()"
           type="file"
           id="file"
           class="d-none"
           accept=".pdf" />
    <div class="toolbar d-flex">
      <!--<span class="my-icon"
  (click)="openLocalFile()"
  title="Open File"></span>-->
      <!--<span class="my-icon"
  [ngClass]="{ active: isOutlineShown }"
  (click)="toggleOutline()"
  title="Toggle Outline">Outline</span>-->

      <span class="my-icon"
            (click)="viewExternalPdf()"
            title="ViewExternalPdf"><i style="cursor:pointer" class="material-icons-outlined md-24">visibility</i></span>

      <span class="my-icon"
            (click)="download()"
            title="DownloadPdf"><i style="cursor:pointer" class="material-icons-outlined md-24">download</i></span>

      <span class="my-icon"
            (click)="printPdf()"
            title="PrintPdf"><i style="cursor:pointer" class="material-icons-outlined md-24">print</i></span>

      <a *ngIf="showEmailIcon" href="mailto:c.vikentiou@ypera.com.cy?body=somebody&subject=someSubjectattachment={{pdfDisplay}}>">
        <span  class="my-icon"
              (click)="sendEmail()"
              title="SendEmail"><i style="cursor:pointer" class="material-icons-outlined md-24">email</i></span>
      </a>

      <span class="seperator-line"></span>

      <span class="my-icon"
            (click)="rotate(-90)"
            title="Rotate Left"><i style="cursor:pointer" class="material-icons-outlined md-24">rotate_left</i></span>

      <span class="my-icon"
            (click)="rotate(90)"
            title="Rotate Right"><i style="cursor:pointer" class="material-icons-outlined md-24">rotate_right</i></span>

      <span class="my-icon"
            (click)="incrementZoom(0.1)"
            title="Zoom In"><i style="cursor:pointer" class="material-icons-outlined md-24">zoom_in</i></span>

      <span class="my-icon"
            (click)="incrementZoom(-0.1)"
            title="Zoom Out"><i style="cursor:pointer" class="material-icons-outlined md-24">zoom_out</i></span>

      <span class="my-icon"
            [ngClass]="{ active: fitToPage }"
            (click)="fitToPage = !fitToPage"
            title="Toggle Fit Page"><i style="cursor:pointer" class="material-icons-outlined md-24">zoom_out_map</i></span>

      <span class="seperator-line"></span>

      <span class="my-icon" style="color:#ffffff !important"
            [ngClass]="{ active: showAll }"
            (click)="showAll = !showAll"
            title="Toggle Show All"><i style="cursor:pointer" class="material-icons-outlined md-24">layers</i></span>

      <ng-container *ngIf="!showAll">
        <span class="my-icon"
              (click)="incrementPage(-1)"
              title="Previous Page"><i style="cursor:pointer" class="material-icons-outlined md-24">keyboard_arrow_left</i></span>

        <div>
          <input type="number"
                 class="page-num"
                 placeholder="Page"
                 [(ngModel)]="page"
                 pattern="-?[0-9]*(\.[0-9]+)?" />
          <span *ngIf="pdf">of {{ pdf.numPages }}</span>
        </div>

        <span class="my-icon"
              (click)="incrementPage(1)"
              title="Next Page"><i style="cursor:pointer" class="material-icons-outlined md-24">keyboard_arrow_right</i></span>
      </ng-container>

      <span class="seperator-line"></span>

      <label class="my-icon" style="color:#ffffff"
             for="searchbox"
             title="Search.."><i style="cursor:pointer" class="material-icons-outlined md-24">search</i></label>
      <input #queryInp
             type="text"
             id="searchbox"
             name="searchbox"
             class="searchbox mr-5"
             placeholder="Search..."
             [value]="pdfQuery"
             (input)="searchQueryChanged($event.target.value)"
             (keyup.enter)="searchQueryChanged(queryInp.value)" />

    </div>

  </div>



  <div class="pdf-body">
    <div *ngIf="isOutlineShown" class="outline">
      <ul *ngFor="let item of outline" class="outline-list">
        <li>
          <a (click)="navigateTo(item.dest)"> {{ item.title }} </a>

          <ul *ngFor="let child of item.items">
            <li>
              <a (click)="navigateTo(child.dest)"> {{ child.title }} </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <pdf-viewer [src]="pdfDisplay"
                [(page)]="page"
                [rotation]="rotation"
                [original-size]="originalSize"
                [fit-to-page]="fitToPage"
                (after-load-complete)="afterLoadComplete($event)"
                [zoom]="zoom"
                [show-all]="showAll"
                [stick-to-page]="stickToPage"
                [render-text]="renderText"
                [external-link-target]="'blank'"
                [autoresize]="autoresize"
                (error)="onError($event)"
                (on-progress)="onProgress($event)"
                (page-rendered)="pageRendered($event)"></pdf-viewer>
  </div>
</div>


