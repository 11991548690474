var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UsersService } from './users.service';
import { ModulesAccessService } from './modules_access.service';
//@Injectable({ providedIn: 'root' })
export class UserInfoService {
    constructor(auth, authHttp, usersService, modulesAccessService) {
        this.auth = auth;
        this.authHttp = authHttp;
        this.usersService = usersService;
        this.modulesAccessService = modulesAccessService;
        this.userName = '<empty>';
        this.userMessagesNum = 0;
        this.isAdmin = false;
        this.isAgent = false;
        this.isDuobond = false;
        this.isLifeline = false;
        this.isOXS = false;
        this.isManualReceiptsRegisterUser = false;
        this.userImpersonations = [];
        this.loggedInUserModulesAccess = [];
        this.loggedInUserGroupsModulesAccess = [];
        this.LoggedInDataComplete = new BehaviorSubject(false);
        //main menu
        this.showMainMenuUsers = false;
        this.startNotificationsService = () => {
            this.authHttp.get('api/notifications/')
                .subscribe(res => {
            });
        };
        this.ImpersonationsSubject = new BehaviorSubject([]);
        this.LoggedInDataCompleteObs = this.LoggedInDataComplete.asObservable();
    }
    setLoggedInUser_working() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.auth.currentUserValue && !this.auth.isTokenExpired) {
                this.loggedInUser = yield this.usersService.getDatabaseUserByUserName(this.auth.currentUserValue["username"]).toPromise();
                this.LoggedInUserSubject = new BehaviorSubject(this.loggedInUser);
                this.loggedInUserObs = this.LoggedInUserSubject.asObservable();
                this.loggedInUserModulesAccess = yield this.modulesAccessService.getByUserID(this.loggedInUser[0]["UserID"]).toPromise();
                this.loggedInUserGroupsModulesAccess = yield this.modulesAccessService.getByUserIDGroups(this.loggedInUser[0]["UserID"]).toPromise();
                this.userImpersonations = yield this.getUserImpersonations().toPromise();
                this.ImpersonationsSubject = new BehaviorSubject(this.userImpersonations);
                this.ImpersonationsObs = this.ImpersonationsSubject.asObservable();
                this.LoggedInDataComplete.next(true);
            }
        });
    }
    setLoggedInUser() {
        if (this.auth.currentUserValue && !this.auth.isTokenExpired) {
            if (!this.LoggedInDataComplete.value) {
                return this.usersService.getDatabaseUserByUserName(this.auth.currentUserValue["username"]).pipe(map(res => {
                    this.loggedInUser = res;
                    this.LoggedInUserSubject = new BehaviorSubject(this.loggedInUser);
                    this.loggedInUserObs = this.LoggedInUserSubject.asObservable();
                }), switchMap(res => this.getSession("impersonated_username")), switchMap(res => this.usersService.getDatabaseUserByUserName(res).pipe(map(res => {
                    this.impersonationAgentSubject = new BehaviorSubject(res[0]);
                    this.impersonationAgentObs = this.impersonationAgentSubject.asObservable();
                }))), switchMap(res => this.modulesAccessService.getByUserID(this.loggedInUser[0]["UserID"]).pipe(map(mas => this.loggedInUserModulesAccess = mas))), switchMap(res => this.modulesAccessService.getByUserIDGroups(this.loggedInUser[0]["UserID"]).pipe(map(ugma => this.loggedInUserGroupsModulesAccess = ugma))), switchMap(res => this.getUserImpersonations().pipe(map(imp => {
                    this.userImpersonations = imp;
                    this.ImpersonationsSubject = new BehaviorSubject(this.userImpersonations);
                    this.ImpersonationsObs = this.ImpersonationsSubject.asObservable();
                    this.LoggedInDataComplete.next(true);
                }))));
            }
            else {
                return EMPTY;
            }
        }
        else {
            return EMPTY;
        }
    }
    get isLoggedInDataComplete() {
        return this.LoggedInDataComplete.value;
    }
    get isLoggedInDataCompleteObs() {
        return this.LoggedInDataCompleteObs;
    }
    get getLoggedInUser() {
        if (this.auth.currentUserValue && !this.auth.isTokenExpired && this.LoggedInDataComplete.value) {
            return this.LoggedInUserSubject.value;
        }
    }
    get getImpersonationAgentCode() {
        if (this.auth.currentUserValue && !this.auth.isTokenExpired && this.LoggedInDataComplete.value) {
            return this.impersonationAgentSubject.value;
        }
    }
    set setImpersonationAgentCode(impersonationAgent) {
        this.impersonationAgentSubject.next(impersonationAgent);
    }
    get getLoggedInUserAccessModules() {
        return this.loggedInUserModulesAccess;
    }
    get getLoggedInUserGroupsAccessModules() {
        return this.loggedInUserGroupsModulesAccess;
    }
    get getLoggedInUserImpersonationsObs() {
        return this.ImpersonationsObs;
    }
    get getLoggedInUserImpersonations() {
        return this.ImpersonationsSubject.value;
    }
    get isAdministrator() {
        if (this.auth.isLoggedIn && this.loggedInUser) {
            if (this.loggedInUser[0]["IsAdministrator"]) {
                return true;
            }
            else
                return false;
        }
        else
            return false;
    }
    clearLoggedInUserData() {
        this.loggedInUser = null;
        this.loggedInUserModulesAccess = [];
        this.loggedInUserGroupsModulesAccess = [];
        this.userImpersonations = [];
        if (this.LoggedInDataComplete != null)
            this.LoggedInDataComplete.next(null);
        if (this.LoggedInUserSubject != null)
            this.LoggedInUserSubject.next(null);
        this.ImpersonationsSubject.next([]);
    }
    allowNavigate(moduleRoute) {
        if (this.auth.isLoggedIn && this.loggedInUser) {
            if (this.getLoggedInUser[0]["IsAdministrator"])
                return true;
            if (moduleRoute.endsWith("LIST")) {
                moduleRoute = moduleRoute.replace("LIST", "");
            }
            if (moduleRoute.endsWith("MENUMAIN")) {
                moduleRoute = moduleRoute.replace("MENUMAIN", "");
            }
            var pAllow = false;
            //Check access based on user groups
            if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && (access.AllowView || access.AllowModify || access.AllowDelete)) >= 0) {
                pAllow = true;
            }
            // Overwrite Group access with the user specific access rights
            if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && (access.AllowView || access.AllowModify || access.AllowDelete)) >= 0) {
                pAllow = true;
            }
            return pAllow;
        }
    }
    allowView(moduleRoute) {
        if (this.auth.isLoggedIn && this.loggedInUser) {
            if (this.loggedInUser[0]["IsAdministrator"])
                return true;
            var pAllow = false;
            //Check access based on user groups
            if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowView) >= 0) {
                pAllow = true;
            }
            // Overwrite Group access with the user specific access rights
            if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowView) >= 0) {
                pAllow = true;
            }
            return pAllow;
        }
    }
    allowModify(moduleRoute) {
        if (this.auth.isLoggedIn && this.loggedInUser) {
            if (this.loggedInUser[0]["IsAdministrator"])
                return true;
            var pAllow = false;
            //Check access based on user groups
            if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowModify) >= 0) {
                pAllow = true;
            }
            // Overwrite Group access with the user specific access rights
            if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowModify) >= 0) {
                pAllow = true;
            }
            return pAllow;
        }
    }
    allowDelete(moduleRoute) {
        if (this.auth.isLoggedIn && this.loggedInUser) {
            if (this.loggedInUser[0]["IsAdministrator"])
                return true;
            var pAllow = false;
            //Check access based on user groups
            if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowDelete) >= 0) {
                pAllow = true;
            }
            // Overwrite Group access with the user specific access rights
            if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowDelete) >= 0) {
                pAllow = true;
            }
            return pAllow;
        }
    }
    getUserImpersonations() {
        if (this.auth.currentUserValue) {
            return this.authHttp.get('api/user/GetUserImpersonations');
        }
    }
    setLoggedInUserSessionInfo() {
        if (this.auth.isLoggedIn) {
            return this.authHttp.get('api/user/setLoggedInUserSessionInfo');
        }
    }
    //public async setLoggedInUserSessionInfo() {
    //  if (this.auth.isLoggedIn) {
    //    return this.authHttp.get('api/user/setLoggedInUserSessionInfo').toPromise();
    //    }
    //}
    setSession(sessionName, sessionValue) {
        var value = sessionValue.toString();
        //if (value.indexOf('.') > 0) {
        //  value = value.replace(/\./g, '*');
        //}    
        //return;
        return this.authHttp.get("api/user/setSessionValue/" + encodeURIComponent(sessionName) + "/" + encodeURIComponent(value));
    }
    getSession(sessionName) {
        return this.authHttp.get("api/user/getSessionValue/" + sessionName.toString());
    }
}
