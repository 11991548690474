import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../_services/loader.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private router: Router) {

  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }


    if (req.url != '/api/authentication/renew' && this.router.url != '/dashboard' && this.router.url != '/sevendaysnoticelist' && this.router.url != '/sevendaysnoticehistory' ) {
      this.loaderService.isLoading.next(this.requests.length > 0);
    }

    if (this.router.url == '/dashboard' ) {
      this.loaderService.isLoading.next(false);
    }

    if (this.requests.length == 0 && this.router.url != '/sevendaysnoticelist' && this.router.url != '/sevendaysnoticehistory') {
      //end of loader
      this.loaderService.isLoading.next(false);
    }

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);

    if (req.url != '/api/authentication/renew' && this.router.url != '/dashboard' && this.router.url != '/sevendaysnoticelist' && this.router.url != '/sevendaysnoticehistory') {
      this.loaderService.isLoading.next(true);
    }

    if (this.router.url == '/dashboard' ) {
      this.loaderService.isLoading.next(false);
    }

    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {

              this.removeRequest(req);
                observer.next(event);
      
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });


  }


}
