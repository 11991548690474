import { PolicyItemObject } from './policyitem';
import { DriverObject } from './driver';

export class PolicyDriverObject {
    policyDriverID: number = undefined;
    policyItemID: number = undefined;
    driverID: number = undefined;
    excess: number = undefined;
    expiryDate: Date = undefined;
    renewal: string = undefined;
    driverCover: string = undefined;
    comments: string = undefined;
    status: string = undefined;
    statusDescription: string = undefined;
    creationDate: Date = undefined;
    createdBy: number = undefined;
    lastUpdateDate: Date = undefined;
    lastUpdatedby: number = undefined;
    policyItem: PolicyItemObject = new PolicyItemObject();
    driver: DriverObject = new DriverObject();
}
