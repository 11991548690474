

<div class="row">



  <div class="col-md-12">


    <!--card is the content-->
    <div>


      <div>
        <h3><b>Version :</b> {{this.versionDetails['Version']}}</h3>
      </div>

      <div>
        <i>Updated At : {{this.versionDetails['UpdatedDate'] | date:'dd-MM-yyyy'}}</i>
      </div>

      <div style="padding-top:10px;">
        <u><b>{{ this.versionDetails['UpdateEntry']  }}</b></u>
      </div>

      <div [innerHTML]="this.versionDetails['Description']">
        
      </div>

    </div>


  </div>

</div>


<!--modal buttons-->
<div class="modal-btns">


  <!--<button type="button" class="btn btn-outline-default btn-round modal-proceed-btn">
    <i class="fa fa-undo"></i>
    Proceed
  </button>-->


  <button mat-dialog-close type="button" class="btn btn-outline-default btn-round modal-close-btn">
    <!--<i class="fa fa-window-close"></i>-->
    <span class="material-icons modal-close-btn-icon">
      close
    </span>
  </button>
</div>


<!--modal buttons-->
<div class="modal-btns">


  <!--<button type="button" class="btn btn-outline-default btn-round modal-proceed-btn">
    <i class="fa fa-undo"></i>
    Proceed
  </button>-->


  <button mat-dialog-close type="button" class="btn btn-outline-default btn-round modal-close-btn">
    <!--<i class="fa fa-window-close"></i>-->
    <span class="material-icons modal-close-btn-icon">
      close
    </span>
  </button>
</div>




