
import { ClientObject } from '../_models/client';
import { ClientGroupsObject } from '../_models/clientgroups';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { FileObject } from '../_models/fileObject';
//import { ResponseContentType } from '@angular/http';


@Injectable()
export class SyClientsService {
  private _Url: string = "/api/syClients/";
  
                                     
  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //unique
  SyGetClientById(id: string, status: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientById/" + encodeURIComponent(id) + "/" + status + "/");
  }

  GetClientByIdentity(identity: string) {    
    return this.authHttp.get<any>(this._Url + "SyGetClientsByIdentity/" + encodeURIComponent(identity));
  }

  GetClientBySySerial(clientSerial: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientBySySerial/" + clientSerial);
  }


  GetClientByFilterSelection(filterType: string, filterValue: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientByFilterSelection/" + filterType + "/" + filterValue);
    
  }

  exportClientToSy(clientData: ClientObject) {
    return this.authHttp.post<any>(this._Url + "exportClientToSy", clientData);
  }

  SyGetClientPolicies(clientSerial: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPolicies/" + clientSerial);
  }
  
  SyGetClientPoliciesAsPerMobileApp(clientSerial: string, loadRowsPage: number, startPageAt: number, optionSelected: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPoliciesAsPerMobileApp/" + clientSerial + "/" + loadRowsPage + "/" + startPageAt + "/" + optionSelected +  "/");

  }

  SyGetClientPoliciesDocumentList(policyNumber: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPoliciesDocumentList/" + policyNumber);
  }

  SyGetClientPoliciesClaims(policyNumber: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPoliciesClaims/" + policyNumber);
  }

  SyGetClientPoliciesDrivers(policySerial: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPoliciesDrivers/" + policySerial);
  }

  SyGetClientPoliciesPaymentsInstallments(policyNumber: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPoliciesPaymentsInstallments/" + policyNumber);
  }
  SyGetClientPendingDocuments(policySerial: string) {
    return this.authHttp.get<any>(this._Url + "SyGetClientPendingDocuments/" + policySerial);
  }
  SySendClientPendingDocuments(policySerial: string, actionSerial: string, recordCode: string, insuranceType: string,remaining:number,bothTypes:boolean,fileObject: FileObject) {
    return this.authHttp.post<any>(this._Url + "SySendClientPendingDocuments/"+ policySerial + "/" + actionSerial + "/" + recordCode + "/" + insuranceType + "/"+remaining+"/"+bothTypes+"/", fileObject);
  }


  getClientsClosedToSeventy(approachingAgeVar: string) {
    return this.authHttp.get<any>(this._Url + "GetClientsClosedToSeventy/" + approachingAgeVar);
  }

  addClientsToSy(clientsToGroup: any = [], mainClientSySerial: number, clientGroupId: number, exceptionInTotalAmount: boolean) {
    return this.authHttp.post<any>(this._Url + "AddClientsToSy/" + mainClientSySerial + "/" + clientGroupId + "/" + exceptionInTotalAmount + "/", clientsToGroup);
  }

  getClientGroupsFromSy() {
    return this.authHttp.get<any>(this._Url + "GetClientGroupsFromSy/");
  }

  acceptClientGroup(clientGroupObject : ClientGroupsObject) {
    return this.authHttp.post<any>(this._Url + "AcceptClientGroup/", clientGroupObject);
  }

  rejectClientGroup(clientGroupObject: ClientGroupsObject) {
    return this.authHttp.post<any>(this._Url + "RejectClientGroup/", clientGroupObject);
  }

}
