
import { EventLogObject } from '../_models/eventlogs';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class EventLogService {
  private _Url: string = "/api/eventLog/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "eventLog";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aEventLogID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aEventLogID + "/");
  }


  add(aEventLog: EventLogObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aEventLog);
  }

  update(aEventLogID: number, aEventLog: EventLogObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aEventLogID + "/", aEventLog);
  }

  delete(aEventLogID: number) {
    let aEventLog: EventLogObject = new EventLogObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aEventLogID + "/", aEventLog)
  }



}
