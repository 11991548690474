export class EmailLogObject {
  EmailLogID: number = 0;
  EntityID: number = 0;
  DocumentType: string = "";
  Status: string = "";
  EmailTo: string = "";
  CreationDate: Date = new Date();
  CreatedBy: number = 0;
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
}
