import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject, fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';


@Injectable()
export class CountdownMessagePopupService {

  private popupVisible = new BehaviorSubject<boolean>(false);
  private popupMessage = new BehaviorSubject<string>('');
  private popupCountdown = new BehaviorSubject<number>(0);

  constructor() {
    this.popupVisible.next(false);
  }

  isPopupVisible() {
    return this.popupVisible.asObservable();
  }

  getPopupMessage() {
    return this.popupMessage.asObservable();
  }

  getPopupCountdown() {
    return this.popupCountdown.asObservable();
  }

  showPopup(message: string, countdown: number) {
    this.popupMessage.next(message);
    this.popupCountdown.next(countdown);
    this.popupVisible.next(true);
  }

  hidePopup() {
    this.popupMessage.next('');
    this.popupCountdown.next(0);
    this.popupVisible.next(false);
  }

  updateCountdown(countdown: number) {
    this.popupCountdown.next(countdown);
  }
}
