import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class OXSService {
  private _Url: string = "/api/OXS/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  Search(Area: string, AccountNumber: string, ContractNumber: string, Name: string, Id: string, Tel1: string, Tel2: string) {
    return this.authHttp.get<any>(this._Url + "Search/" + Area + "/" + AccountNumber + "/" + ContractNumber + "/" + Name + "/" + Id + "/" + Tel1 + "/" + Tel2 + "/");
  }

  getByID(aOXSID: number) {
    return this.authHttp.get<any>(this._Url + "GetByID/" + aOXSID + "/");
  }

}
