

export class AttachmentsObject {

  attachmentID: number = 0;
  taskID: number = 0 ;
  attachmentType: string = "";
  attachmentContents: string = "";
  attachmentFilename: string = "";
  lastUpdatedby: number = 0;
  createdBy: number = 0;
  status: string = "";

}
