
import { PolicyPermissionObject } from '../_models/policypermission';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PolicyPermissionService {
  private _Url: string = "/api/policyPermission/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "policyPermission";


  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aPolicyPermissionID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aPolicyPermissionID + "/");
  }


  add(aPolicyPermission: PolicyPermissionObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aPolicyPermission);
  }

  update(aPolicyPermissionID: number, aPolicyPermission: PolicyPermissionObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aPolicyPermissionID + "/", aPolicyPermission);
  }

  delete(aPolicyPermissionID: number) {
    let aPolicyPermission: PolicyPermissionObject = new PolicyPermissionObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aPolicyPermissionID + "/", aPolicyPermission)
  }

  //unique
  getPolicyPermissionByUserID(userID: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyPermissionByUserID/" + userID + "/");
  }


}
