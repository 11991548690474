
import { SMSLogObject } from '../_models/smslogs';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class SMSLogService {
  private _Url: string = "/api/smsLog/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "smsLog";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aSMSLogID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aSMSLogID + "/");
  }


  add(aSMSLog: SMSLogObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aSMSLog);
  }

  update(aSMSLogID: number, aSMSLog: SMSLogObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aSMSLogID + "/", aSMSLog);
  }

  delete(aSMSLogID: number) {
    let aSMSLog: SMSLogObject = new SMSLogObject();
    //console.log(aSMSLog);
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aSMSLogID + "/", aSMSLog)
  }



}
