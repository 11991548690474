
import { PolicyDriverObject } from '../_models/policydriver';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PolicyDriverService {
  private _Url: string = "/api/policyDriver/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "policyDriver";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aPolicyDriverID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aPolicyDriverID + "/");
  }


  add(aPolicyDriver: PolicyDriverObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aPolicyDriver);
  }

  update(aPolicyDriverID: number, aPolicyDriver: PolicyDriverObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aPolicyDriverID + "/", aPolicyDriver);
  }

  delete(aPolicyDriverID: number) {
    let aPolicyDriver: PolicyDriverObject = new PolicyDriverObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aPolicyDriverID + "/", aPolicyDriver)
  }

  //unique
  getPolicyDriversByPolicyID(policyId: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyDriversByPolicyID/" + policyId + "/");
  }

  getPolicyDriverByDriverID(driverID: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyDriverByDriverID/" + driverID + "/");
  }

  getPolicyDriversByPolicyItemID(policyItemID: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyDriversByPolicyItemID/" + policyItemID + "/");
  }


}
