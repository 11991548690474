
import { JCCDepositObject } from '../_models';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class JCCDepositService {
  private _Url: string = "/api/jccpaymentproducts/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

    }

  getAll(aUserID: number) {
    return this.authHttp.get<any>(this._Url + "ALL" + "/"+ aUserID);
  }

  getByID(aJCCDepositID: number, aUserID:number) {
    return this.authHttp.get<any>(this._Url + "ID" + "/" + aJCCDepositID + "/"+aUserID+"/");
  }

  getReceipts(aJCCDepositID: number, aUserID: number) {
    return this.authHttp.get<any>(this._Url + "REC" + "/" + aJCCDepositID + "/" + aUserID + "/");
  }
  postJCCDepositToSynthesis(aJCCDepositID: number, userID: number) {
    return this.authHttp.get<any>(this._Url + "PostJCCDepositToSynthesis/" + aJCCDepositID + "/" + userID + "/");
  }

  add(aJCCDeposit: JCCDepositObject) {    
    return this.authHttp.post<any>(this._Url + "Modify/" + "I" + "/", aJCCDeposit);
  }
  JCCGetSettings() {
    return this.authHttp.get<any>(this._Url + "JCCGetSettings/");
  }

  update(aJCCDepositID: number, aJCCDeposit: JCCDepositObject) {
    return this.authHttp.post<any>(this._Url + "Modify/" + "U" + "/" + aJCCDepositID + "/", aJCCDeposit);
  }

  delete(aJCCDepositID: number) {
    let aJCCDeposit: JCCDepositObject = new JCCDepositObject();
    return this.authHttp.post<any>(this._Url + "Modify/" + "D" + "/" + aJCCDepositID, aJCCDeposit)
  }


  getDepositsList() {
    return this.authHttp.get<any>(this._Url + "GetDepositsList/LST"  + "/0/");
  }


  getDepositsListADP() {
    return this.authHttp.get<any>(this._Url + "GetDepositsList/ADP" + "/0/");
  }

  getDepositsListADPByProductFormID(productFormID: number) {
    return this.authHttp.get<any>(this._Url + "GetDepositsList/DPL" + "/0/" + productFormID + "/");
  }

}
