
import { HTMLtoPDFObject } from '../_models/htmltopdfobject';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { escapeLeadingUnderscores } from 'typescript';
import { NotificationsService, ToasterPosition, ToasterType } from './notifications.service';


@Injectable()
export class DocumentsService {
  private _Url: string = "/api/SyDocuments/";

  constructor(private authHttp: HttpClient,
    private auth: AuthenticationService,
    private notService: NotificationsService
  ) {

  }

  //all methods are unique

  getHTMLToPDF(aObject: HTMLtoPDFObject) {
    return this.authHttp.post<any>("/api/ValidationRule/Branch/FormValidation" + "/", aObject);
  }

  
downloadPolicyDocument(DocumentType, PolicyNumber,PolicySerial,ClientSerial,UserID) {
    var result;

  this.authHttp.get(this._Url + "DownloadPolicyDocuments/" + DocumentType + "/" + PolicyNumber + "/" + PolicySerial + "/" + ClientSerial + "/" + UserID, { responseType: 'blob' }).pipe(
    map(x => {
      if (x.type == "text/plain") {
        return "FileNotFound";
      } else {
        return new Blob([x], { type: "application/pdf" });
      }
      }
      ))
      .subscribe(response => {        
        result = response;        
      },
        error => {
          //console.log(error);
        },                                                   
        () => {
          if (result != "FileNotFound") {
            var blob = new Blob([result], { type: 'application/pdf' });
            var filename = DocumentType + '_' + PolicyNumber + '.pdf';
            var fileSaver = require('file-saver');
            fileSaver.saveAs(blob, filename);
          }
          else {
            this.notService.show(ToasterType.error, "The selected document is currently unavailable", ToasterPosition.topCenter, 6000)

          }
        });
}

  getAllFileΝames(pathName: string) {
    return this.authHttp.get<any>(this._Url + 'GetAllFileΝames?pathName=' + pathName);
  }



  getFile(fileName, pathName: string) {
    return this.authHttp.get<any>(this._Url + 'GetFile?fileName=' + fileName + '&pathName=' + pathName);
  }

  genericGetAllFileΝames(pathName: string) {
    return this.authHttp.get<any>(this._Url + 'GenericGetAllFileΝames?pathName=' + pathName);
  }



  genericGetFile(fileName, pathName: string) {
    return this.authHttp.get<any>(this._Url + 'GenericGetFile?fileName=' + fileName + '&pathName=' + pathName);
  }

  addClientGroupFilesToDirectory(fileObjectsList: any[], pathName: string, fileName: string, createPerAgentSubFolders: boolean, clientGroupId: number) {
    return this.authHttp.post<any>(this._Url + "AddClientGroupFilesToDirectory?pathName=" + pathName + "&fileName=" + fileName + "&createPerAgentSubFolders=" + createPerAgentSubFolders + "&clientGroupId=" + clientGroupId, fileObjectsList);
  }


 
}
