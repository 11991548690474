/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./helppdfcustomviewer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory";
import * as i5 from "ng2-pdf-viewer";
import * as i6 from "./helppdfcustomviewer.component";
import * as i7 from "../../_services/notifications.service";
var styles_HelpPDFCustomViewerComponent = [i0.styles];
var RenderType_HelpPDFCustomViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpPDFCustomViewerComponent, data: {} });
export { RenderType_HelpPDFCustomViewerComponent as RenderType_HelpPDFCustomViewerComponent };
function View_HelpPDFCustomViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "SendEmail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["email"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mailto:c.vikentiou@ypera.com.cy?body=somebody&subject=someSubjectattachment=", _co.pdfDisplay, ">"); _ck(_v, 0, 0, currVal_0); }); }
function View_HelpPDFCustomViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["of ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdf.numPages; _ck(_v, 1, 0, currVal_0); }); }
function View_HelpPDFCustomViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "Previous Page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementPage((0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "input", [["class", "page-num"], ["pattern", "-?[0-9]*(\\.[0-9]+)?"], ["placeholder", "Page"], ["type", "number"]], [[1, "pattern", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 7).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.page = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(7, 16384, null, 0, i2.NumberValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵdid(8, 540672, null, 0, i2.PatternValidator, [], { pattern: [0, "pattern"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.PatternValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.NumberValueAccessor]), i1.ɵdid(11, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPDFCustomViewerComponent_3)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "Next Page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementPage(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_right"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "-?[0-9]*(\\.[0-9]+)?"; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.page; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.pdf; _ck(_v, 15, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 8).pattern ? i1.ɵnov(_v, 8).pattern : null); var currVal_1 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 13).ngClassValid; var currVal_6 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_HelpPDFCustomViewerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo(_v.context.$implicit.dest) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); }); }
function View_HelpPDFCustomViewerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ul", [["class", "outline-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo(_v.context.$implicit.dest) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPDFCustomViewerComponent_6)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); }); }
function View_HelpPDFCustomViewerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "outline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPDFCustomViewerComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.outline; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HelpPDFCustomViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pdfComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 52, "div", [["class", "pdf-whole-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 46, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["accept", ".pdf"], ["class", "d-none"], ["id", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 44, "div", [["class", "toolbar d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "ViewExternalPdf"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewExternalPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["visibility"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "DownloadPdf"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["download"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "PrintPdf"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.printPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["print"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPDFCustomViewerComponent_1)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "span", [["class", "seperator-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "Rotate Left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotate((0 - 90)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["rotate_left"])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "Rotate Right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotate(90) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["rotate_right"])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "Zoom In"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementZoom(0.1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["zoom_in"])), (_l()(), i1.ɵeld(26, 0, null, null, 2, "span", [["class", "my-icon"], ["title", "Zoom Out"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementZoom((0 - 0.1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["zoom_out"])), (_l()(), i1.ɵeld(29, 0, null, null, 5, "span", [["class", "my-icon"], ["title", "Toggle Fit Page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.fitToPage = !_co.fitToPage) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(31, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(32, { active: 0 }), (_l()(), i1.ɵeld(33, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["zoom_out_map"])), (_l()(), i1.ɵeld(35, 0, null, null, 0, "span", [["class", "seperator-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 5, "span", [["class", "my-icon"], ["style", "color:#ffffff !important"], ["title", "Toggle Show All"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showAll = !_co.showAll) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(38, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(39, { active: 0 }), (_l()(), i1.ɵeld(40, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["layers"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPDFCustomViewerComponent_2)), i1.ɵdid(43, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(44, 0, null, null, 0, "span", [["class", "seperator-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 2, "label", [["class", "my-icon"], ["for", "searchbox"], ["style", "color:#ffffff"], ["title", "Search.."]], null, null, null, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 1, "i", [["class", "material-icons-outlined md-24"], ["style", "cursor:pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["search"])), (_l()(), i1.ɵeld(48, 0, [["queryInp", 1]], null, 0, "input", [["class", "searchbox mr-5"], ["id", "searchbox"], ["name", "searchbox"], ["placeholder", "Search..."], ["type", "text"]], [[8, "value", 0]], [[null, "input"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.searchQueryChanged($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.searchQueryChanged(i1.ɵnov(_v, 48).value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 4, "div", [["class", "pdf-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPDFCustomViewerComponent_4)), i1.ɵdid(51, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(52, 0, null, null, 1, "pdf-viewer", [], null, [[null, "pageChange"], [null, "after-load-complete"], [null, "error"], [null, "on-progress"], [null, "page-rendered"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 53).onPageResize() !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = ((_co.page = $event) !== false);
        ad = (pd_1 && ad);
    } if (("after-load-complete" === en)) {
        var pd_2 = (_co.afterLoadComplete($event) !== false);
        ad = (pd_2 && ad);
    } if (("error" === en)) {
        var pd_3 = (_co.onError($event) !== false);
        ad = (pd_3 && ad);
    } if (("on-progress" === en)) {
        var pd_4 = (_co.onProgress($event) !== false);
        ad = (pd_4 && ad);
    } if (("page-rendered" === en)) {
        var pd_5 = (_co.pageRendered($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i4.View_PdfViewerComponent_0, i4.RenderType_PdfViewerComponent)), i1.ɵdid(53, 9158656, [[1, 4]], 0, i5.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], page: [1, "page"], renderText: [2, "renderText"], originalSize: [3, "originalSize"], showAll: [4, "showAll"], stickToPage: [5, "stickToPage"], zoom: [6, "zoom"], rotation: [7, "rotation"], externalLinkTarget: [8, "externalLinkTarget"], autoresize: [9, "autoresize"], fitToPage: [10, "fitToPage"] }, { afterLoadComplete: "after-load-complete", pageRendered: "page-rendered", onError: "error", onProgress: "on-progress", pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showEmailIcon; _ck(_v, 15, 0, currVal_0); var currVal_1 = "my-icon"; var currVal_2 = _ck(_v, 32, 0, _co.fitToPage); _ck(_v, 31, 0, currVal_1, currVal_2); var currVal_3 = "my-icon"; var currVal_4 = _ck(_v, 39, 0, _co.showAll); _ck(_v, 38, 0, currVal_3, currVal_4); var currVal_5 = !_co.showAll; _ck(_v, 43, 0, currVal_5); var currVal_7 = _co.isOutlineShown; _ck(_v, 51, 0, currVal_7); var currVal_8 = _co.pdfDisplay; var currVal_9 = _co.page; var currVal_10 = _co.renderText; var currVal_11 = _co.originalSize; var currVal_12 = _co.showAll; var currVal_13 = _co.stickToPage; var currVal_14 = _co.zoom; var currVal_15 = _co.rotation; var currVal_16 = "blank"; var currVal_17 = _co.autoresize; var currVal_18 = _co.fitToPage; _ck(_v, 53, 1, [currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18]); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.pdfQuery; _ck(_v, 48, 0, currVal_6); }); }
export function View_HelpPDFCustomViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "helppdfcustomviewer", [], null, null, null, View_HelpPDFCustomViewerComponent_0, RenderType_HelpPDFCustomViewerComponent)), i1.ɵdid(1, 114688, null, 0, i6.HelpPDFCustomViewerComponent, [i7.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpPDFCustomViewerComponentNgFactory = i1.ɵccf("helppdfcustomviewer", i6.HelpPDFCustomViewerComponent, View_HelpPDFCustomViewerComponent_Host_0, { pdfDisplay: "pdfDisplay", showEmailIcon: "showEmailIcon", clientEmail: "clientEmail", agentEmail: "agentEmail" }, {}, []);
export { HelpPDFCustomViewerComponentNgFactory as HelpPDFCustomViewerComponentNgFactory };
