
import { DocumentCodeObject } from '../_models/document_codes';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class DocumentCodesService {
  private _Url: string = "/api/documentCode/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "documentCode";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aDocumentCodeID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aDocumentCodeID + "/");
  }

  getByName(aDocumentCodeName: string) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/NM/" + aDocumentCodeName + "/");
  }

  add(aDocumentCode: DocumentCodeObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I", aDocumentCode + "/");
  }

  update(aDocumentCodeID: number, aDocumentCode: DocumentCodeObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aDocumentCodeID + "/", aDocumentCode);
  }

  delete(aDocumentCodeID: number) {
    let aDocumentCode: DocumentCodeObject = new DocumentCodeObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aDocumentCodeID + "/", aDocumentCode)
  }

}
