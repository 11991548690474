<div class="row">
  <div class="col-md-12">
    <div class="card ">
      <div class="card-header ">
        <h5 class="card-title">ERROR</h5>
        <p class="card-category">An error has occured!</p>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-history"></i> Please contact Ypera IT department
        </div>
      </div>
    </div>
  </div>
</div>
