import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserInfoService } from '.';


@Injectable()
export class SetLoggedInUserResolverService implements Resolve<Observable<any>> {
  constructor(private userService: UserInfoService) {
  }

  resolve(): Observable<any> {    
    return this.userService.setLoggedInUser();

  }
}
