
import { Users } from '../classes/user';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';



@Injectable()
export class UsersService {
    private _Url: string = "/api/user/"


  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

    }

  getUsers() {    
    return this.authHttp.get<any>(this._Url + "ALL" + "/");
  }

  getActiveUsers() {
    return this.authHttp.get<any>(this._Url + "ACT" + "/");
  }

  getByID(aUserID: number) {
    return this.authHttp.get<any>(this._Url + "GetModified/ID/" + aUserID + "/");
  }

  getDatabaseUserByUserName(username) {
    return this.authHttp.get<any>(this._Url + "GetModified/USR/0/" + username + "/");
  }

  add(aUser: Users) {
    return this.authHttp.post<any>(this._Url + "Modify/" + "I" + "/", aUser);
  }

  update(aUserID: number, aUser: Users) {
    return this.authHttp.post<any>(this._Url + "Modify/" + "U" + "/" + aUserID + "/", aUser);
  }

  delete(aUserID: number) {
    let aUser: Users = new Users();
    return this.authHttp.post<any>(this._Url + "Modify/" + "D" + "/" + aUserID + "/", aUser)
  }

  forgotPassword(username: string) {
    return this.authHttp.get<any>(this._Url + "ForgotPassword/" + username + "/");
  }

  forgotPassword_ChangePassword(userID: number, username : string, password: string) {
    return this.authHttp.get<any>(this._Url + "ForgotPassword_ChangePassword?userID=" + userID + "&username=" + username + "&password=" + password);
  }

  forgotPassword_CheckURLExpire(urlExpire: string) {
    return this.authHttp.get<any>(this._Url + "ForgotPassword_CheckURLExpire?urlExpire=" + urlExpire);
  }

  versionModalUpdate() {
    return this.authHttp.get<any>(this._Url + "VersionModalUpdate/");
  }

    //deleteUser(userID: number) {
    //    return this._authHttp.delete(this._Url + userID)
    //        .map(response => response.json());

    //}

    //setSession(sessionName: string, sessionValue: string) {
    //    var value: string = sessionValue.toString();        
    //    if (value.indexOf('.') > 0) {
    //        value = value.replace(/\./g, '*');
    //    }
    //    return this._authHttp.get(this._Url + "setSessionValue/" + sessionName + "/" + value+"/")
    //        .map(response => response.json());
    //}

    //getSession(sessionName) {
    //    return this._authHttp.get(this._Url + "getSessionValue/" + sessionName+"/")
    //        .map(response => response.json());
    //}





}
