import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { fromEvent, interval, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { CountdownMessagePopupService } from './countdowmessagepopup.service';
import { UserInfoService } from './userInfo.service';


@Injectable()
export class IdleService {

  //private inactivityTimeout = 5 * 1000; // 1 minutes in milliseconds
  private inactivityTimeout = 15 * (60 * 1000); // 15 minutes
  public popupTimer = 120;
  //public popupTimer = 5;
  public inactivityTimer: any;
  public countdownInterval: any;

   mousemove_EndSubscription: Subscription;
   mousedown_EndSubscription: Subscription;
   keydown_EndSubscription: Subscription;
   touchstart_EndSubscription: Subscription;
   touchmove_EndSubscription: Subscription;
   touchend_EndSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private countdownmessagepopupService: CountdownMessagePopupService,
    private usrInfo: UserInfoService
  ) {

                   

  }

  public setupInactivityDetection() {
    // Listen to user activity events (e.g., mousemove, keydown)
    this.mousemove_EndSubscription = fromEvent(document, 'mousemove').pipe(
      debounce(() => interval(50)) // Adjust debounce time as needed
    ).subscribe(() => this.resetInactivityTimer());

    this.mousedown_EndSubscription = fromEvent(document, 'mousedown').pipe(
      debounce(() => interval(100))
    ).subscribe(() => this.resetInactivityTimer());

    this.keydown_EndSubscription = fromEvent(document, 'keydown').pipe(
      debounce(() => interval(100))
    ).subscribe(() => this.resetInactivityTimer());

    //mobile devices
    this.touchstart_EndSubscription = fromEvent(document, 'touchstart').pipe(
      debounce(() => interval(50)) // Adjust debounce time to 50 milliseconds
    ).subscribe(() => this.resetInactivityTimer());

    this.touchmove_EndSubscription = fromEvent(document, 'touchmove').pipe(
      debounce(() => interval(50))
    ).subscribe(() => this.resetInactivityTimer());

    this.touchend_EndSubscription = fromEvent(document, 'touchend').pipe(
      debounce(() => interval(50))
    ).subscribe(() => this.resetInactivityTimer());

    // Initialize inactivity timer
    this.startInactivityTimer();
  }

  private startInactivityTimer() {
    this.inactivityTimer = setTimeout(() => {
      //console.log('starting inactivity');
      this.countdownmessagepopupService.showPopup('', this.popupTimer);
      this.startCountdown();
    }, this.inactivityTimeout);
  }

  private resetInactivityTimer() {
    //console.log('Timer is reset ');
    //hide the popup
    this.countdownmessagepopupService.hidePopup();
    //clear timeouts
    this.StopAllTimers();
    //start the timer again for inactivity
    this.startInactivityTimer();
  }

  public StopAllTimers() {
    //clear inactivity timer
    if (this.inactivityTimer != undefined) {
      clearTimeout(this.inactivityTimer);
    }

    //clear popup timer
    if (this.countdownInterval != undefined) {
      clearInterval(this.countdownInterval);
    }
  }

  public StopAllTimersAndEvents() {

    this.StopAllTimers();
    this.StopAllEvents();

  }

  public StopAllEvents() {
    //stop the events
    if (this.mousemove_EndSubscription != undefined) {
      this.mousemove_EndSubscription.unsubscribe();
    }
    if (this.mousedown_EndSubscription != undefined) {
      this.mousedown_EndSubscription.unsubscribe();
    }
    if (this.keydown_EndSubscription != undefined) {
      this.keydown_EndSubscription.unsubscribe();
    }
    if (this.touchstart_EndSubscription != undefined) {
      this.touchstart_EndSubscription.unsubscribe();
    }
    if (this.touchmove_EndSubscription != undefined) {
      this.touchmove_EndSubscription.unsubscribe();
    }
    if (this.touchend_EndSubscription != undefined) {
      this.touchend_EndSubscription.unsubscribe();
    }
  }

  private startCountdown() {
    let countdown = this.popupTimer;

    this.countdownInterval = setInterval(() => {
      countdown--;

      if (countdown <= 0) {

  

        //logout the user out
        //console.log('Log out from idle.service.ts');
        this.authenticationService.logout(); // Logout user after countdown
        //hide the idle popup
        this.countdownmessagepopupService.hidePopup();

        this.usrInfo.clearLoggedInUserData();

        //clear all timers
        this.StopAllTimersAndEvents();
      
      } else {
        //update the popup timer
        this.countdownmessagepopupService.updateCountdown(countdown);
      }

    }, 1000 /*count each second*/);
  }

}
