import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { UserInfoService } from '../_services/userInfo.service';
//import { ReceiptService } from '../../services/receipts/receipt.service';
import { NavItem } from '../_models';
import { ProtectorService, ReceiptService, SystemValuesService } from '../_services';
import { TranslateService } from '@ngx-translate/core';
import { userInfo } from 'os';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
  { path: '/userlist', title: 'Users List', icon: 'nc-diamond', class: '' },
  { path: '/maps', title: 'Maps', icon: 'nc-pin-3', class: '' },
  { path: '/notifications', title: 'Notifications', icon: 'nc-bell-55', class: '' },
  { path: '/user', title: 'User Profile', icon: 'nc-single-02', class: '' },
  { path: '/table', title: 'Table List', icon: 'nc-tile-56', class: '' },
  { path: '/typography', title: 'Typography', icon: 'nc-caps-small', class: '' },
  { path: '/upgrade', title: 'Upgrade to PRO', icon: 'nc-spaceship', class: 'active-pro' }
];




export const NAVITEMS: NavItem[] = [
  { displayName: 'Dashboard', iconName: 'dashboard', route: 'dashboard' },
  { displayName: 'Travel Insurance', subTitle: 'For Help Call : 25055555', iconName: 'airplanemode_active', route: 'travelinsurance' },
  {
    displayName: 'Receipts', iconName: 'receipt', route: 'receipt', expanded:false,
    children: [
      {
        displayName: 'Receipts List', iconName: 'list', route: 'receiptlist'
      },
      {
        displayName: 'Create New Receipt', iconName: 'add', route: 'receipt',
      },
      { displayName: 'Receipts Not Deposited', iconName: 'article', route: 'receiptsnotdepositedlist' }
    ]
  },
  { displayName: 'DuoBond', iconName: 'directions_car', route: 'duobond' },
  { displayName: 'Odyky', iconName: 'add_road', route: 'odyky' },
  { displayName: 'Life Line', iconName: 'airplanemode_active', route: 'lifeline' },
  { displayName: 'OXS', iconName: 'account_balance', route: 'oxslist' },
  {
    displayName: 'Clients', iconName: 'face', route: 'clientmenumain', expanded: false,
    children: [
      { displayName: 'Information', iconName: 'face', route: 'clientlist' },
      //{ displayName: 'Create Corporate Groups', iconName: 'face', route: 'clientgroups' },
      { displayName: 'Corporate Groups', iconName: 'face', route: 'clientgroupslist' },
      { displayName: 'Corporate Groups List Exceptions', iconName: 'face', route: 'clientgroupslistexceptions' },
    ]
  },
  {
    displayName: 'Reports', iconName: 'article', route: 'reports',
    //children: [
    //  { displayName: 'Client Statement', iconName: 'article', route: 'clientstatement' },
    //  { displayName: 'Client Statement - Only Outstanding Balances', iconName: 'article', route: 'customeroutstandingbalances', fontSize: '13px' },
    //  { displayName: 'Client Statement - Only Installment Balances', iconName: 'article', route: 'outstandinginstallment', fontSize: '13px' },
    //  { displayName: 'Client Birthday Statement', iconName: 'article', route: 'clientbirthdatestatement' },
    //  { displayName: 'Commission Statement', iconName: 'article', route: 'commissionstatement' },
    //  { displayName: 'Production Analysis', iconName: 'article', route: 'productionanalysis' },
    //  { displayName: 'Lapsed - Not Renewed Policies', iconName: 'article', route: 'lapsednotrenewedpolicies', fontSize: '13px' },
    //  { displayName: 'Receipts Per Agent', iconName: 'article', route: 'receiptsperagent' },
    //  { displayName: '7DN Cash Responsibility', iconName: 'article', route: 'sevendaysnoticeresponsibility' },
    //  { displayName: 'Income Tax Certificate 1101', iconName: 'article', route: 'incometaxcertificate1101' },
    //  { displayName: 'Client Claims History', iconName: 'article', route: 'clientclaimshistory' },

    //]
  },
  {
    displayName: 'Manual Receipts', iconName: 'receipt_long', route: 'manualreceiptregistermenumain', expanded: false,
    children: [
      { displayName: 'Blocks Register', iconName: 'receipt_long', route: 'manualreceiptregisterlist' },
    ]
  },
  { displayName: 'TOM Vehicles', iconName: 'local_shipping', route: 'vehicle_info_rtd' },
  { displayName: 'Assist America', iconName: 'medical_services', route: 'assistamerica' },
  /*  { displayName: 'T/C Mobile App', iconName: 'import_export', route: 'tcmobileappexport' },  */
  {
    displayName: 'JCC', iconName: 'payment', route: 'jccmenumain', expanded: false,
    children: [
      { displayName: 'Deposits', iconName: 'credit_card', route: 'jccdepositslist' },
      { displayName: 'Statements Import', iconName: 'article', route: 'jccstatementslist' }
    ]
  },
  {
    displayName: 'Pancyprian', iconName: 'personal_injury', route: 'pancypriantransferpoliciesmenumain', expanded: false,
    children: [
      { displayName: 'Policies Management', iconName: 'content_copy', route: 'pancypriantransferpolicies' }
    ]
  }, {
    displayName: 'Covernotes', iconName: 'time_to_leave', route: 'covernotesmenumain', expanded: false,
    children: [
      { displayName: 'Issue Covernote', iconName: 'garage', route: 'covernoteissue' }
    ]
  },
  {
    displayName: 'Car Abroad', iconName: 'garage', route: 'forms', expanded: false,
    children: [
      { displayName: 'Search Car Cover', iconName: 'garage', route: 'carabroadcheck' },
      { displayName: 'Create Car Cover', iconName: 'garage', route: 'carabroadcover' },
      { displayName: 'Sepa', iconName: 'article', route: 'sepaform' }
    ]
  },
  {
    displayName: 'Task Management System', iconName: 'assignment', route: 'taskmanagement', expanded: false,
    children: [
      { displayName: 'Task Management List', iconName: 'assignment', route: 'taskmanagementlist' },
      { displayName: 'Create New Task', iconName: 'assignment', route: 'taskmanagement' }
    ]
  },
  {
    displayName: 'Refunds', iconName: 'dashboard', route: 'refunds', expanded: false,
    children: [
      { displayName: 'List', iconName: 'dashboard', route: 'refundslist' },
      { displayName: 'History', iconName: 'dashboard', route: 'refundshistory' }
    ]
  },


  {
    displayName: '7 Days', iconName: 'dashboard', route: 'sevendaysnotice', expanded: false,
    children: [
      { displayName: '7 Days Notice List', iconName: 'dashboard', route: 'sevendaysnoticelist' },
      { displayName: 'Accounting Details', iconName: 'dashboard', route: 'sevendaysnoticeaccountingdetails' },
      { displayName: 'History of 7 Days Notice', iconName: 'dashboard', route: 'sevendaysnoticehistory' },
      { displayName: '7 Days Notice Not Proceed Yet', iconName: 'dashboard', route: 'sevendaysnoticenotproceedyet' },
      { displayName: '7DN Check Filters', iconName: 'article', route: 'sevendayscheckfilters' },
    ]
  },

  {
    displayName: 'Documents', iconName: 'description', route: 'documents', expanded: false,
    children: [
      { displayName: 'List', iconName: 'description', route: 'documentslist' },
      { displayName: 'Profit Commission', iconName: 'picture_as_pdf', route: 'profitcommission' },
      { displayName: 'Commission Confirmation', iconName: 'picture_as_pdf', route: 'commissionconfirmationlist' },
      { displayName: 'Production Confirmation', iconName: 'picture_as_pdf', route: 'workcertificate' },

    ]
  },

  {
    displayName: 'Products', iconName: 'description', route: 'products', expanded: false,
    children: [
      { displayName: 'Create New Product', iconName: 'create_new_folder', route: 'productscategorieslist', gParams: 'P' },
      { displayName: 'New Cover Note', iconName: 'create_new_folder', route: 'covernoteform', gParams:  'NEW' },
      { displayName: 'List', iconName: 'folder_open', route: 'userproductsformslist' },
      { displayName: 'Cover Notes Approvals', iconName: 'folder_open', route: 'covernotesapprovals' },
      //{ displayName: 'Individuals', iconName: 'picture_as_pdf', route: 'productscategorieslist', gParams: 'P' },
      //{ displayName: 'Business & Professionals', iconName: 'picture_as_pdf', route: 'productscategorieslist', gParams: 'C' },
      { displayName: 'Transactions', iconName: 'paid', route: 'productformtransactions' }
    ]
  },

  {
    displayName: 'Claims', iconName: 'description', route: 'claims', expanded: false,
    children: [
      { displayName: 'Claim Related Codes', iconName: 'picture_as_pdf', route: 'claimrelatedcodes' },
    ]
  },
  {
    displayName: 'Accounting', iconName: 'receipt', route: 'accounting', expanded: false,
    children: [
      { displayName: 'Import Bank Statement', iconName: 'article', route: 'bankstatementimporting', visible: false },
      { displayName: 'View Bank Payments', iconName: 'article', route: 'viewbankpayments', visible: false },
      { displayName: 'View Ignored Bank Payments', iconName: 'article', route: 'viewignoredbankpayments', visible: false },
      { displayName: 'Generate Production Certificates', iconName: 'article', route: 'generatecertificates', visible: false },
      { displayName: 'Auto Update Receipts', iconName: 'update', button: true,functionToCall:'receiptsAutoProcess'  , route: '', visible: false },
      { displayName: 'Send Direct Debit email', iconName: 'email', button: true, functionToCall:'sendDirectDebitEmail'  , route: '', visible: false },
    ]
  },
  {
    displayName: 'Underwriting', iconName: 'receipt', route: 'underwriting', expanded: false,
    children: [
      { displayName: 'Communication Templates', iconName: 'article', route: 'communicationtemplates', visible: false },
      { displayName: 'Update Synthesis Cover Note', iconName: 'article', route: 'updatesynthesiscovernote', visible: false },
    ]
  },


  //not visible we need them just for the page title
  { displayName: 'Blocks Register', iconName: 'receipt_long', route: 'manualreceiptregister', disabled: true },
  { displayName: 'Client Information', iconName: 'face', route: 'client', disabled: true },
  { displayName: 'Client Information', iconName: 'face', route: 'clientinformation', disabled: true },
  { displayName: 'Users', iconName: 'face', route: 'userlist', disabled: true },
  { displayName: 'Login', iconName: 'face', route: 'login', disabled: true },
  { displayName: 'Branches', iconName: 'face', route: 'branchlist', disabled: true },
  { displayName: 'Branches', iconName: 'face', route: 'branch', disabled: true },
  { displayName: 'Groups', iconName: 'face', route: 'grouplist', disabled: true },
  { displayName: 'Groups', iconName: 'face', route: 'group', disabled: true },
  { displayName: 'User Groups', iconName: 'face', route: 'usergrouplist', disabled: true },
  { displayName: 'User Groups', iconName: 'face', route: 'usergroup', disabled: true },
  { displayName: 'Modules', iconName: 'face', route: 'modulelist', disabled: true },
  { displayName: 'Modules', iconName: 'face', route: 'module', disabled: true },
  { displayName: 'Modules Access', iconName: 'face', route: 'modulesaccesslist', disabled: true },
  { displayName: 'Modules Access', iconName: 'face', route: 'modulesaccess', disabled: true },
  { displayName: 'Report Recreation', iconName: 'face', route: 'reportrecreationlist', disabled: true },
  { displayName: 'Report Recreation', iconName: 'face', route: 'reportrecreation', disabled: true },
  { displayName: 'Communication Templates', iconName: 'face', route: 'communicationtemplates', disabled: true },
  

];


@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  public menuItems: any[];
  private _runningUpdate = 0;

  public navItems = NAVITEMS;

  public DBName = '';

  constructor(public authService: AuthenticationService
    , public usrInfo: UserInfoService, private systemValuesService: SystemValuesService
    , public translate: TranslateService, private protectService: ProtectorService,) {

  }


  ngOnInit() {

    //console.log('navItems : ', this.navItems);

    //get the index in order to see if the client already exist in our list
    var index = this.navItems.findIndex(x => x.displayName === 'Products');

    //check if this client exist  and do not insert them in our list
    if (index != -1) {

      var children = this.navItems[index]['children'];

      var index = children.findIndex(x => x.displayName === 'New Cover Note');

      if (index != -1) {
        //change name
        children[index]['gParams'] = this.protectService.encryptionTypescript('NEW')
      }


    }

    this.menuItems = ROUTES.filter(menuItem => menuItem);

    this.systemValuesService.GetDBName().subscribe(res => {

      this.DBName = res.responseObject;

    },
      error => {
        //  this.notificationService.show(ToasterType.error, error, ToasterPosition.topCenter, 4000);
      });
  }



  //runUpdateDailyReport() {
  //  this._runningUpdate = 1;

  //  this._receiptService.updateReceiptsDailyReportForAllAgent().subscribe(res => {
  //  },
  //    error => {
  //  
  //    
  //    },
  //    () => {

  //      this.modal.alert()
  //        .size('sm')
  //        .isBlocking(true)
  //        .showClose(false)
  //        .keyboard(0)
  //        .title('Update Completed')
  //        .body('The report has been updated successfully!')
  //        .okBtn('OK')
  //        .open().then((resultPromise) => {
  //      
  //          resultPromise.result.then((result) => {
  //                                                                     

  //          },
  //            (result) => {
  //             

  //            });
  //        });

  //      this._runningUpdate = 2;
  //    });
  //}
  
}


