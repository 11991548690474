import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationControlService {
  private isActive = false;

  setActive(active: boolean) {
    this.isActive = active;
  }

  isImpersonationActive(): boolean {                
    return this.isActive;
  }
}
