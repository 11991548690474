
import { BranchObject } from '../_models/branch';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ProtectorService } from './protector.service';


@Injectable()
export class BranchService {
    private _Url: string = "/api/generic";
  private _controllerName: string = "branch";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService, public protectorService: ProtectorService) {

    }


  getAll() {
    return this.authHttp.get<any>(this._Url + '?' + encodeURIComponent(this.protectorService.encryptionTypescript('entityType=' + this._controllerName + "&run_Code=ALL" )));
  }

  getByID(aBranchID: number) {
    return this.authHttp.get<any>(this._Url + '?' + encodeURIComponent(this.protectorService.encryptionTypescript('entityType=' + this._controllerName + '&run_Code=ID' + '&id=' + aBranchID)));
  }


  add(aBranch: BranchObject) {
    return this.authHttp.post<any>(this._Url + '/Modify?' + encodeURIComponent(this.protectorService.encryptionTypescript('entityType=' + this._controllerName + '&run_Code=A' + '&id=')), aBranch);
  }

  update(aBranchID: number, aBranch: BranchObject) {
    return this.authHttp.post<any>(this._Url + '/Modify?' + encodeURIComponent(this.protectorService.encryptionTypescript('entityType=' + this._controllerName + '&run_Code=U' + '&id=' + aBranchID)), aBranch);
  }

  delete(aBranchID: number) {
    let aBranch: BranchObject = new BranchObject();
    return this.authHttp.post<any>(this._Url + '/Modify?' + encodeURIComponent(this.protectorService.encryptionTypescript('entityType=' + this._controllerName + "&run_Code=D" + "&id=" + aBranchID)), aBranch);
  }

}
