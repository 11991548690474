
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ccworksheetsObject } from '../_models/ccworksheets';
import { FileObject } from '../_models/fileObject';


@Injectable()
export class RefundsService {
  private _Url: string = "/api/refunds/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "refunds";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  // all methods are unique

  getAllRefunds() { 
    return this.authHttp.get<any>(this._Url + 'GetAllRefunds/' );
  }


  getAllRefundsSubmited() {
    return this.authHttp.get<any>(this._Url + 'GetAllRefundsSubmited/');
  }


  getAllPendingRefunds() {
    return this.authHttp.get<any>(this._Url + 'GetAllPendingRefunds/');
  }


  getAllRefundsExtranet() {
    return this.authHttp.get<any>(this._Url + 'GetAllRefundsExtranet/');
  }


  getAllRefundsHistoryAgents() {
    return this.authHttp.get<any>(this._Url + 'GetAllRefundsHistoryAgents/');
  }


  getAllRefundsHistory() {
    return this.authHttp.get<any>(this._Url + 'GetAllRefundsHistory/');
  }


  insertRefunds(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'InsertRefunds/', aEntityObject);
  }

  //insertRefundsTest(aEntityObject: object) {
  //  return this.authHttp.post<any>(this._Url + 'SynthesisInsert/', aEntityObject);
  //}


  approveCCWorksheets(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'SynthesisUpdateCCWorksheets/', aEntityObject);
  }
  transferRefundToAnotherClient(postData: object) {
    return this.authHttp.post<any>(this._Url + 'TransferRefundToAnotherClient', postData);
  }

  rejectionCCWorksheets(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'RejectPendingEntriesToCCworksheets/', aEntityObject);
  }


  syGetAccountUnAllocatedDocuments(accountCode: string) {
    return this.authHttp.get<any>(this._Url + "SyGetAccountUnAllocatedDocumentsRefunds/" + accountCode + "/");
  }
  uploadClientIBAN(agentCode:string,policyNumber:string,fileObject:FileObject) {
    return this.authHttp.post<any>(this._Url + "UploadClientIBAN/"+agentCode+"/"+policyNumber+"/", fileObject);
  }

}
