

export class TaskManagementObject {

  taskID: number = 0;
  taskName: string = "";
  taskDescription: string = "";
  estimatedDate: Date = new Date();
  status = "";
  priority: number = 0;
 /* selectedUser: string = "";*/
  userID: number = 0;
  comments = "";
  lastUpdatedby: number = 0;
  createdBy: number = 0;
  attachmentContents: any = null;
  attachmentFilename: string = "";

}
