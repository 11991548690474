import * as i0 from "@angular/core";
export class ImpersonationControlService {
    constructor() {
        this.isActive = false;
    }
    setActive(active) {
        this.isActive = active;
    }
    isImpersonationActive() {
        return this.isActive;
    }
}
ImpersonationControlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImpersonationControlService_Factory() { return new ImpersonationControlService(); }, token: ImpersonationControlService, providedIn: "root" });
