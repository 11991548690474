
import { LoginLogObject } from '../_models/loginlogs';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class LoginLogService {
  private _Url: string = "/api/loginLog";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "loginLog";


  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aLoginLogID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aLoginLogID + "/");
  }


  add(aLoginLog: LoginLogObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aLoginLog);
  }

  update(aLoginLogID: number, aLoginLog: LoginLogObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aLoginLogID + "/", aLoginLog);
  }

  delete(aLoginLogID: number) {
    let aLoginLog: LoginLogObject = new LoginLogObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aLoginLogID + "/", aLoginLog)
  }



}
