import { User } from './user';

export class SubUserObject {

  subUserID: number;
  userID: number;
  linkedUserID: number;
  status: string;
  createdBy: number;
  creationDate: Date;
  lastUpdateDate: Date;
  lastUpdatedBy: number;
  User: User = new User();
  LinkedUser: User = new User();
}
