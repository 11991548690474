import { AttachmentsObject } from '../_models/attachments';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ProtectorService } from './protector.service';


@Injectable()
export class AttachmentsService {
    private _Url: string = "/api/generic";
  private _controllerName: string = "attachments";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService, public protectorService: ProtectorService) {

    }

  //unique
  getAllTaskID(taskID) {
    return this.authHttp.get<any>('api/' + this._controllerName + '/getAllTaskID/TID/' + taskID + '/');
  }

  getAllActiveTaskID(taskID) {
    return this.authHttp.get<any>('api/' + this._controllerName + '/getAllTaskID/TAC/' + taskID + '/');
  }

  //generic

  getAll() {
    return this.authHttp.get<any>(this._Url + '/'  + this._controllerName + "/ALL/");
  }

  getAllActive() {
    return this.authHttp.get<any>(this._Url + '/' + this._controllerName + "/ACT/");
  }

  getByID(aAttachmentsID: number) {
    return this.authHttp.get<any>(this._Url + '/GetByID/' + this._controllerName + '/ID/' + aAttachmentsID + '/');
  }


  add(aEntityObject: AttachmentsObject) {
    return this.authHttp.post<any>('api/' + this._controllerName + "/Modify/I/", aEntityObject);
  }

  update(aEntityID: number, aEntityObject: AttachmentsObject) {
    return this.authHttp.post<any>('api/' + this._controllerName + "/Modify/U/" + aEntityID + "/", aEntityObject);
  }

  delete(aEntityID: number, aEntityObject: AttachmentsObject) {
    //let aEntityObject: Object = new Object();

    return this.authHttp.post<any>('api/' + this._controllerName + "/Modify/D/" + aEntityID, aEntityObject)
  }

  insertUpdate(aActionCode: string, aEntityID: number, aEntityObject: AttachmentsObject = null) {

    if (aActionCode == 'I') {
      return this.authHttp.post<any>('api/' + this._controllerName + "/Modify/I", aEntityObject);
    }
    else if (aActionCode == 'U') {
      return this.authHttp.post<any>('api/' + this._controllerName + "/Modify/U" + "/" + aEntityID, aEntityObject);
    }

  }

}
