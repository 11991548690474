
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class CarAbroadCoverService {
  private _Url: string = "/api/carabroadcover/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "carabroadcover";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }
    

  getAllPolicyNumbers() {
    return this.authHttp.get<any>(this._Url + "GetAllPolicyNumbers/");
  }


  sendEmailTest() {
    return this.authHttp.get<any>(this._Url + 'SendEmailTest/');
  }


  sendEmail(policyNumber, insuredID, insuredName, insuredSurname, occupation, carRegistrationNo, dateFrom, dateTo) {
    return this.authHttp.get<any>(this._Url + "SendEmail/" + policyNumber + "/" + encodeURIComponent(insuredID) + "/" + encodeURIComponent(insuredName) + "/" + encodeURIComponent(insuredSurname) + "/" + encodeURIComponent(occupation) + "/" + encodeURIComponent(carRegistrationNo) + "/" + dateFrom + "/" + dateTo + "/");
  } 
    

}
