
import { DriverObject } from '../_models/driver';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class DriverService {
  private _Url: string = "/api/driver/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "driver";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //all methods are unique

  getAll() {
    return this.authHttp.get<any>(this._Url + "/");
  }

  getAllActive() {
    return this.authHttp.get<any>(this._Url + "GetAllActive/");
  }

  getByID(id: number) {
    return this.authHttp.get<any>(this._Url + "GetByID/" + id + "/");
  }

  getByIdentity(identity: string) {
    return this.authHttp.get<any>(this._Url + "GetByIdentity/" + identity + "/");
  }

  filterByIdentity(identity: string) {
    return this.authHttp.get<any>(this._Url + "FilterByIdentity/" + identity + "/");
  }

  add(aDriver: DriverObject) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/I" + "/", aDriver);
  }

  update(aDriverID: number, aDriver: DriverObject) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/U/" + aDriverID + "/", aDriver);
  }

  delete(aDriverID: number) {
    let aDriver: DriverObject = new DriverObject();
    return this.authHttp.post<any>(this._Url + "Modify" + "/D/" + aDriverID + "/", aDriver)
  }






}
