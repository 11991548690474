import { BranchObject } from "./branch";

export class User {
  userID: number = 0;
  username: string = "";
  description: string = "";
  email: string = "";
  isAdministrator: boolean = false;
  isMasterAccount: boolean = false;
  syUsername: string = "";
  status: string = "";
  fKUserMenuID: number = 0;
  agentCode: string = "";
  subAgentCode: string = "";
  fKBranchID: number = 0;
  agentSerial: number = 0;
  signature_EN: string = "";
  signature_GR: string = "";
  level: number = 0;
  phone: number = 0;
  statusDescription: string = "";
  fKUserAccessGroupID: number = 0;
  impersonationBranches: string = "";
  impersonationUsers: string = "";
  commissionAccount: string = "";
  oldCommissionAccount: string = "";
  showPreviousAgentSerials: string = "";
  discountAllowance: number = 0;
  discountAllowancePerPolicy?: number = 0;
  transferAllowancePerPolicy?: number = 0;
  salt: string = "";
  branch: BranchObject = new BranchObject();

  token?: string;
}
