
import { SubUserObject } from '../_models/subuser';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class SubUserService {
  private _Url: string = "/api/SubUser/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "SubUser";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aSubUserID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aSubUserID + "/");
  }


  add(aSubUser: SubUserObject) {
    aSubUser.User = null;
    aSubUser.LinkedUser = null;
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aSubUser);
  }

  update(aSubUserID: number, aSubUser: SubUserObject) {
    aSubUser.User = null;
    aSubUser.LinkedUser = null;
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aSubUserID + "/", aSubUser);
  }

  delete(aSubUserID: number) {
    let aSubUser: SubUserObject = new SubUserObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aSubUserID + "/", aSubUser)
  }

  //unique
  getSubUsersByUserID(userID: number) {
    return this.authHttp.get<any>(this._Url + "getSubUsersByUserID/" + userID + "/");
  }




}
