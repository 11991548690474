
import { ReceiptObject } from '../_models/receipt';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';



@Injectable()
export class ReceiptService {
  private _Url: string = "/api/receipt/";
  private _genericUrl: string = "/api/generic/";
  private _documentsUrl: string = "/api/SyDocuments/";
  private _controllerName: string = "receipt";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {


  }

  //generic

  getByID(aReceiptID: number) {
    return this.authHttp.get<any>(this._Url + "ID/" + aReceiptID + "/");
  }

  add(aReceipt: ReceiptObject) {
    return this.authHttp.post<any>(this._Url + "Modify/I/", aReceipt);
  }

  update(aReceiptID: number, aReceipt: ReceiptObject) {
    return this.authHttp.post<any>(this._Url + "Modify/U/" + aReceiptID + "/", aReceipt);
  }

  insertUpdate(aActionCode: string, aEntityID: number, aEntityObject: Object = null) {

    if (aActionCode == 'I') {
      return this.authHttp.post<any>(this._Url + "Modify/I", aEntityObject);
    }
    else if (aActionCode == 'U') {
      return this.authHttp.post<any>(this._Url + "Modify/U" + "/" + aEntityID, aEntityObject);
    }
  }

  delete(aReceiptID: number) {
    let aReceipt: ReceiptObject = new ReceiptObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aReceiptID + "/", aReceipt)
  }

  getDatabaseUserByUserName(username) {

    return this.authHttp.get<any>(this._Url + "GetModified/USR/0/" + username + "/");
  }

  getReceiptsListAll() {
    return this.authHttp.get<any>(this._Url + "GetReceiptsList" + "/");
  }
  getReceiptsListEntryByReceiptID(receiptID: number) {
    return this.authHttp.get<any>(this._Url + "GetReceiptsList" + "/" + receiptID + "/");
  }

  syGetReceiptNumberAndCode(receiptID: number) {
    return this.authHttp.get<any>(this._Url + "SyGetReceiptNumberAndCode/" + receiptID + "/");
  }

  postReceiptToSynthesis(receiptID: number, userID: number) {
    return this.authHttp.get<any>(this._Url + "PostReceiptToSynthesis/" + receiptID + "/" + userID + "/");
  }

  getPostingData(receiptID: number) {
    return this.authHttp.get<any>(this._Url + "GetPostingData/" + receiptID + "/");
  }

  exportReceiptToSy() {
    return this.authHttp.get<any>(this._Url + "ExportReceiptToSy" + "/");
  }

  //getReceiptsDailyReportByAgent() {
  //  return this.authHttp.get<any>(this._Url + "GetReceiptsDailyReportByAgent" + "/");
  //}

  getReceiptsDailyReportByUser() {
    return this.authHttp.get<any>(this._Url + "GetReceiptsDailyReportByUser" + "/");
  }

  getReceiptsDailyReportByUserPassDueDate() {
    return this.authHttp.get<any>(this._Url + "GetReceiptsDailyReportByUserPassDueDate" + "/");
  }

  sendDailyReceiptEmails() {
    return this.authHttp.get<any>(this._Url + "SendDailyReceiptEmails" + "/");
  }

  //updatePaidReceiptForAllAgents() {
  //  return this.authHttp.get<any>(this._Url + "UpdatePaidReceiptForAllAgents" + "/");
  //}

  checkReceiptFileExists(receiptID, userID) {

    return this.authHttp.get(this._documentsUrl + "DownloadReceipt/" + receiptID + "/CheckFileExists/" + userID + "/")
  }

  checkReceiptDiscountFileExists(receiptID, userID) {

    return this.authHttp.get(this._documentsUrl + "DownloadDiscountReceipt/" + receiptID + "/CheckFileExists/" + userID + "/")
  }


  startDownloadReceipt(receiptID, userID) {
    var result;

    this.authHttp.get(this._documentsUrl + "DownloadReceipt/" + receiptID + "/Download/" + userID, { responseType: 'blob' }).pipe(
      map(x => {
        return new Blob([x], { type: "application/pdf" });
      }
      ))
      .subscribe(response => {

        result = response;
      },
        error => {

        },
        () => {
          var blob = new Blob([result], { type: 'application/pdf' });
          var filename = 'Receipt_' + receiptID + '.pdf';
          var fileSaver = require('file-saver');
          fileSaver.saveAs(blob, filename);
        });
  }

  startDownloadDiscountReceipt(receiptID, userID) {
    var result;

    this.authHttp.get(this._documentsUrl + "DownloadDiscountReceipt/" + receiptID + "/Download/" + userID, { responseType: 'blob' }).pipe(
      map(x => {
        return new Blob([x], { type: "application/pdf" });
      }
      ))
      .subscribe(response => {
        result = response;
      },
        error => {
        },
        () => {
          var blob = new Blob([result], { type: 'application/pdf' });
          var filename = 'Discount_' + receiptID + '.pdf';
          var fileSaver = require('file-saver');
          fileSaver.saveAs(blob, filename);
        });
  }
  sendReceiptByEmail(receiptID: number, mailTo: string, bcc: boolean, lang?: string) {
    mailTo = mailTo.replace(/\./g, '^');
    return this.authHttp.get(this._documentsUrl + "SendReceiptByEmail/" + receiptID + "/" + mailTo + "/" + bcc+"/"+lang);
  }

  sendReceiptBySMS(receiptID: number, phoneNumber: string, lang?: string) {

    phoneNumber = phoneNumber.replace('+', '00').replace(' ', '');
    return this.authHttp.get(this._documentsUrl + "SendReceiptBySMS/" + receiptID + "/" + phoneNumber+"/"+lang);
  }

  updatePaidReceiptsByAgent() {
    return this.authHttp.get<any>(this._Url + "UpdatePaidReceiptsByAgent/");
  }
  autoReceiptsProcedure() {
    return this.authHttp.get<any>(this._Url +"UpdateSynthesisReceipts/")
  }

  getAllNotPrintedReceipts(runMode) {
    return this.authHttp.get<any>(this._Url + "GetAllNotPrintedReceipts/" + runMode + "/" + 0 + "/");
  }

  getAllPrintJobsForReceiptID (receiptID: number) {
    return this.authHttp.get<any>(this._Url + "GetAllPrintJobsForReceiptID/RID/" + receiptID + "/");
  }

  generateReceiptBasedOnIDRange_Synthesis(receiptIDFirst: number, receiptIDLast: number,) {
    return this.authHttp.get<any>(this._documentsUrl + "GenerateReceiptBasedOnIDRange/" + receiptIDFirst + "/" + receiptIDLast + "/SYNTHESIS/");

  }
}
