import { Component, Input, OnInit } from '@angular/core';
import { CountdownMessagePopupService } from '../../_services/countdowmessagepopup.service';

@Component({
  selector: 'countdown-message',
  templateUrl: './countdown-message.component.html',
  styleUrls: ['./countdown-message.component.scss']
})
export class CountdownMessageComponent implements OnInit {

  //@Input() message: string;
  //@Input() countdown: number;
  isVisible: boolean = false;
  message: string = '';
  countdown: number = 0;

  constructor(private countdownMessagePopupService: CountdownMessagePopupService) {

  }

  ngOnInit() {
    this.countdownMessagePopupService.isPopupVisible().subscribe(visible => {
      this.isVisible = visible;
    });

    this.countdownMessagePopupService.getPopupMessage().subscribe(message => {
      this.message = message;
    });

    this.countdownMessagePopupService.getPopupCountdown().subscribe(countdown => {
      this.countdown = countdown;
    });
    
  }



}
