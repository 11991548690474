
export class DocumentCodeObject {

  DocumentCodeID: number = 0;
  BranchID: number = 0;
  CreatedBy: number = 0;
  CreationDate: Date = new Date();
  Description: string = "";
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
  MaxDocumentNumber: number = 0;
  ReserveAccount: string = "";
  Status: string = "";
  isPostDated: boolean = false;
  isManual: boolean = false;

}
