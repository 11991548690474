// src/app/error.service.ts
import { Injectable, ErrorHandler, Injector, NgZone, Component } from '@angular/core';
import { ToastrService } from "ngx-toastr";



export enum ToasterPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  topCenter = 'toast-top-center',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left',
  bottomCenter = 'toast-bottom-center'
  // Other positions you would like
}

export enum ToasterType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
  primary = 'primary'
}

@Injectable()
export class NotificationsService {

  constructor(private toastr: ToastrService) {

  }

  show( type: ToasterType,message: string,position: ToasterPosition,timeout: number) {
    switch (type) {
      case 'info':
        this.toastr.info(
          '<span class="nc-icon nc-bell-55"></span><span>' + message + '</span>',
          "",
          {
            timeOut: timeout,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-info alert-with-icon",
            positionClass: position
          }
        );
        break;
      case 'success':
        this.toastr.success(
          '<span class="nc-icon nc-bell-55"></span><span>' + message + '</span>',
          "",
          {
            timeOut: timeout,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: position
          }
        );
        break;
      case 'warning':
        this.toastr.warning(
          '<span class="nc-icon nc-bell-55"></span><span>' + message + '</span>',
          "",
          {
            timeOut: timeout,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: position
          }
        );
        break;
      case 'error':
        this.toastr.error(
          '<span class="nc-icon nc-bell-55"></span><span>' + message + '</span>',
          "",
          {
            timeOut: timeout,
            enableHtml: true,
            closeButton: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: position
          }
        );
        break;
      case 'primary':
        this.toastr.show(
          '<span class="nc-icon nc-b-55"></span><span>' + message + '</span>',
          "",
          {
            timeOut: timeout,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-primary alert-with-icon",
            positionClass: position
          }
        );
        break;
      default:
        break;
    }

  }
  



}

