
import { PolicyPermissionLineObject } from '../_models/policypermissionline';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PolicyPermissionLineService {
  private _Url: string = "/api/policyPermissionLine/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "policyPermissionLine";


  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aPolicyPermissionLineID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aPolicyPermissionLineID + "/");
  }


  add(aPolicyPermissionLine: PolicyPermissionLineObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aPolicyPermissionLine);
  }

  update(aPolicyPermissionLineID: number, aPolicyPermissionLine: PolicyPermissionLineObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aPolicyPermissionLineID + "/", aPolicyPermissionLine);
  }

  delete(aPolicyPermissionLineID: number) {
    let aPolicyPermissionLine: PolicyPermissionLineObject = new PolicyPermissionLineObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aPolicyPermissionLineID + "/", aPolicyPermissionLine)
  }

  //unique
  getPolicyPermissionLineByPolicePermissonID(policyPermissionID: number) {
    return this.authHttp.get<any>(this._Url + "getPolicyPermissionLineByPolicePermissonID/" + policyPermissionID + "/");
  }

  getPolicyPermissionLineByGcInsuranceTypeID(gcInsuranceTypeID: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyPermissionLineByGcInsuranceTypeID/" + gcInsuranceTypeID + "/");
  }


}
