
export class RecurrentTaskObject {

  RecurrentTaskID: number = 0;
  TaskName: string = "";
  IsEnabled: boolean = false;
  IsRunning: boolean = false;
  CallClass: string = "";
  CallMethod: string = "";
  IntervalCronTiming: string = "";
  CreationDate: Date = new Date();
  CreatedBy: number = 0;
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
  Status: string = "";
}
