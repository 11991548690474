
import { ClientBalancesObject } from '../_models/client_balances';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class ClientBalancesService {
    private _Url: string = "/api/ClientBalances";


  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

    public SyFilterAgentBalances(filterType: string, filterValue: string) {
        if (filterType == 'ACCOUNT') {
            filterValue = this.ConvertAccountNumber(filterValue);
      }

      return this.authHttp.get<any>(this._Url + "/SyFilterAgentBalances/" + filterType + "/" + filterValue + "/");
    }

    public SyFilterAgentBalancesByPeriod(filterType: string, filterValue: string, periodFrom: number, yearFrom: number, periodTo: number, yearTo: number) {
        if (filterType == 'ACCOUNT') {
            filterValue = this.ConvertAccountNumber(filterValue);
        }
      return this.authHttp.get<any>(this._Url + "/SyFilterAgentBalancesByPeriod/" + filterType + "/" + filterValue + "/" + periodFrom + "/" + yearFrom + "/" + periodTo + "/" + yearTo + "/");
   
    }


    public SyFilterAgentBalancesPerClient(filterType: string, filterValue: string) {
        if (filterType == 'ACCOUNT') {
            filterValue = this.ConvertAccountNumber(filterValue);
        }
      return this.authHttp.get<any>(this._Url + "/SyFilterAgentBalancesPerClient/" + filterType + "/" + filterValue + "/");
      
    }

    //receipt ids => can accept more than one separated by ,
    public SyGetTransactionsAllocation(receiptIds: string) {
      return this.authHttp.get<any>(this._Url + "/SyGetTransactionsAllocation/" + receiptIds + "/");
      
    }

    public SyGetAccountBalance(accountId: string) {
      return this.authHttp.get<any>(this._Url + "/SyGetAccountBalance/" + accountId + "/");
       
    }

    private ConvertAccountNumber(accountNum: string) {
        return accountNum.split('.').join('-');
    }

    public SyGetClientAccountStatement(clientAccount: string, fromYear: number, toYear: number, fromPeriod: number, toPeriod: number) {
      return this.authHttp.get<any>(this._Url + "/SyGetClientAccountStatement/" + clientAccount + "/" + fromYear + "/" + toYear + "/" + fromPeriod + "/" + toPeriod + "/");
           
  }

  public SyGetAccountBalanceAsAtToday(accountCode: string) {
    return this.authHttp.get<any>(this._Url + "/SyGetAccountBalanceAsAtToday/" + accountCode + "/");

  }

  public SyGetAccountBalanceDetails() {
    //console.log("SyGetAccountBalanceDetails test");
    return this.authHttp.get<any>(this._Url + "/SyGetAccountBalanceDetails/");

  }


}
