import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { MenuListItemComponent } from '../components-global/menu-list-item/menu-list-item.component';
import { MaterialModule } from '../modules/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [RouterModule,
    CommonModule,
    MaterialModule,
    TranslateModule  ],
  declarations: [SidebarComponent,
    MenuListItemComponent],
  exports: [SidebarComponent, TranslateModule]
})

export class SidebarModule { }

