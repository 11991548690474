
import { BranchObject } from '../_models/branch';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class ModulesAccessService {
  private _Url: string = "/api/modulesaccess/";



  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

    }

  getByID(aId: number) {
    return this.authHttp.get<any>(this._Url + "ID" + "/" + aId + "/");
    }

  getByUserID(aUserId: number) {
    return this.authHttp.get<any>(this._Url + "ID" + "/" + aUserId + "/UserID/");
  }

  getByUserIDGroups(aUserId: number) {
    return this.authHttp.get<any>(this._Url + "ID" + "/" + aUserId + "/UserGroups/");
  }

  getByModuleID(aModuleId: number) {
    return this.authHttp.get<any>(this._Url + "ID" + "/" + aModuleId + "/ModuleID/");
  }

}
