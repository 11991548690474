import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserInfoService, AuthenticationService } from '../../_services';
import { MaterialModule } from '../../modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VersionModalModule } from '../../pages/versionmodal/versionmodal.module';
import { TranslateModule } from '@ngx-translate/core';
//import { AdminLayoutModule } from '../../layouts/admin-layout/admin-layout.module';

//import { VersionModalComponent } from '../../pages/versionmodal/versionmodal.component';

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


@NgModule({
  imports: [RouterModule, CommonModule, NgbModule, MaterialModule, FormsModule, ReactiveFormsModule, VersionModalModule, TranslateModule],
  declarations: [NavbarComponent],
  exports: [NavbarComponent, TranslateModule]//,
  //entryComponents: [VersionModalComponent]
})

export class NavbarModule {
  constructor(auth: AuthenticationService, _userInfo: UserInfoService) {
        //library.add(faSignInAlt, faSignOutAlt);
  }

}


