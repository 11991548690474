

<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" style=" margin-bottom: 0px;" (click)="onItemSelected(item)"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
   class="menu-list-item" *ngIf="((item.route!='' && this.usrInfo.allowNavigate(item.route.toUpperCase()))||item.route=='') &&
   (item.disabled == null || item.disabled == false)">
  <mat-icon class="material-icons-outlined routeIcon mat-custom-icon">{{item.iconName}}</mat-icon>
  <span [ngStyle]="{'font-size' : item.fontSize != undefined ? item.fontSize : '16px'}" style="width:300px">{{item.displayName | translate}}

    <span *ngIf="item.subTitle != undefined" style="font-size:12px;"><br>{{item.subTitle | translate}}</span>
  </span>

  <span fxFlex *ngIf="item.children && item.children.length" style="width:100%;">
    <div class="mat-custom-icon-container">
      <mat-icon class="material-icons-outlined mat-custom-icon" [@indicatorRotate]="item.expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </div>
  </span>

</a>
<div *ngIf="item.expanded" style="border-top: 1px solid grey">
  <app-menu-list-item style="background-color: #ebe7e7;" *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>

