
import { BranchObject } from '../_models/branch';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { OTPLogObject } from '../_models/otplog';


@Injectable()
export class OTPLogService {
    private _Url: string = "/api/otplog/";



  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

    }

  sendOTP(sendType: string, otpModel: OTPLogObject) {
    return this.authHttp.post<any>(this._Url + "SendOTP/" + sendType + "/", otpModel);
    }

  verifyOTP(OTPID : number, otpModel: OTPLogObject) {
    return this.authHttp.post<any>(this._Url + "VerifyOTP/" + OTPID + "/", otpModel);
  }

}
