import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AccountingService {
  private _Url: string = "api/accounting";
  constructor(private authHttp:HttpClient) {

  }
  getAgentsWithPremium(year:any,agentGroupCodes:string[]) {
    return this.authHttp.post<any>(this._Url + "/GetAgentsWithPremium/"+year+"/", agentGroupCodes);
  }
  createAgentCertificates(year:any,agentsData: any) {
    return this.authHttp.post<any>(this._Url + "/CreateAgentCertificates/"+year+"/", agentsData);
  }
}
