export class SMSLogObject {
  SMSLogID: number = 0;
  EntityID: number = 0;
  SMSReason: string = "";
  Status: string = "";
  RecipientNumber: string = "";
  CreationDate: Date = new Date();
  CreatedBy: number = 0;
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
}
