
import { PolicyItemObject } from '../_models/policyitem';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PolicyItemService {
  private _Url: string = "/api/policyItem/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "policyItem";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aPolicyItemID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aPolicyItemID + "/");
  }


  add(aPolicyItem: PolicyItemObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aPolicyItem);
  }

  update(aPolicyItemID: number, aPolicyItem: PolicyItemObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aPolicyItemID + "/", aPolicyItem);
  }

  delete(aPolicyItemID: number) {
    let aPolicyItem: PolicyItemObject = new PolicyItemObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aPolicyItemID + "/", aPolicyItem)
  }

  //unique
  getPolicyItemsByItemCode(itemCode: string) {
    return this.authHttp.get<any>(this._Url + "GetPolicyItemsByItemCode/" + itemCode + "/");
  }

  getPolicyItemsByItemID(itemID: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyItemsByItemID/" + itemID + "/");
  }

  getPolicyItemsByPolicyID(policyID: number) {
    return this.authHttp.get<any>(this._Url + "GetPolicyItemsByPolicyID/" + policyID + "/");
  }


}
