
export class Users {

    userID: number = 0;
    createdBy: number = 0;
    creationDate: Date = new Date(); 
    description: string = "";
    email: string = "";
    isAdministrator: boolean = false;
    lastUpdateDate: Date = new Date();
    lastUpdatedby: number = 0;
    status: string = "";
    statusDescription: string = "";
    username: string = "";
    agentCode: string = "";
    agentSerial: number = 0;
    level: number = 0;
    phone: number = 0;
    signature_EN: string = "";
    signature_GR: string = "";
    subAgentCode: string = "";
    fKBranchID: number = 0;
    fKUserMenuID: number = 0;
    fKUserAccessGroupID: number = 0;
    impersonationBranches: string = "";
    impersonationUsers: string = "";
    commissionAccount: string = "";
    showPreviousAgentSerials: string = "";


}
