<footer class="footer">
    <div class="container-fluid">
        <!--<nav class="pull-left">
            <ul>

                <li>
                    <a href="https://www.creative-tim.com">
                        Creative Tim
                    </a>
                </li>
                <li>
                    <a href="http://blog.creative-tim.com">
                       Blog
                    </a>
                </li>
                <li>
                    <a href="https://www.creative-tim.com/license">
                        Licenses
                    </a>
                </li>
            </ul>
        </nav>-->
      <div class="copyright pull-right">
        <!--&copy; {{test | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by <a href="http://www.ydrogios.com.cy/">Ydrogios Insurance Company (Cyprus) Ltd</a>-->
        &copy; {{test | date: 'yyyy'}}, made by <a href="http://www.ydrogios.com.cy/">Ypera Insurance Co. Ltd</a>
      </div>
    </div>
</footer>
