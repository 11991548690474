export class PostingReceiptRequestObject {
  postingReceiptRequestID: number = 0;
  receiptID: number = 0;
  userID: number = 0;
  requestStatus: string = "";
  creationDate: Date = new Date();
  createdBy: number = 0;
  lastUpdateDate: Date = new Date();
  lastUpdatedby: number = 0;
}
