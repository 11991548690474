import { AuthenticationService, UserInfoService } from '../_services';
import { Observable } from 'rxjs';

export function appInitializer(authenticationService: AuthenticationService, userInfo: UserInfoService) {
  return () => new Observable(resolve => {

    let currentUser = authenticationService.currentUserValue;

    if (currentUser && currentUser.accessToken) {
      if (!authenticationService.isTokenExpired) {
        
        authenticationService.renew().subscribe();      //CHARIS CHANGE       
      }
      else {
        console.log('Log out from app.initializer.ts');
        authenticationService.logout();
      }
    } else {
      //console.log("CURRENT USER NOT FOUND IN APP INITIALIZER");

    }

  }).subscribe(() => { console.log("appInitializer Run"); });

}
