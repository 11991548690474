export class ItemObject {
    itemID: number = 0;
    registration: string = "";
    sashi: string = "";
    bodyTypeSerial: string = "";
    bodyType: string = "";
    makeSerial: number = 0;
    make: string = "";
    isSport: string = "";
    rTDModel: string = "";
    model: string = "";
    modelSerial: number = 0;
    cubicCapacity: number = 0;
    grossWeight: number = 0;
    manufactureYear: number = 0;
    seatingCapacity: number = 0;
    convertible: string = "";
    createdBy: number = 0;
    creationDate: Date = new Date();    
    lastUpdateDate: Date = new Date();
    lastUpdatedby: number = 0;
    status: string = "";
    insuranceSubForm: string = "";
    modelCode: string = "";
    makeCode: string = "";
    bodyTypeCode: string = "";
}
