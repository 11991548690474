
import { PolicyCoverNotesObject } from '../_models/policy_cover_notes';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PolicyCoverNotesService {
  private _Url: string = "/api/policyCoverNote/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "policyCoverNote";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  add(aPolicyCoverNotes: PolicyCoverNotesObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aPolicyCoverNotes);
  }

  update(aPolicyCoverNotesID: number, aPolicyCoverNotes: PolicyCoverNotesObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aPolicyCoverNotesID + "/", aPolicyCoverNotes);
  }

  delete(aPolicyCoverNotesID: number) {
    let aPolicyCoverNotes: PolicyCoverNotesObject = new PolicyCoverNotesObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aPolicyCoverNotesID + "/", aPolicyCoverNotes)
  }

  //unique
  getAll(agentCode: string) {
    return this.authHttp.get<any>(this._Url + "GetModified" + "/ALL/" + agentCode + "/");
  }

  getByID(agentCode: string, id: number) {
    return this.authHttp.get<any>(this._Url + "GetModified" + "/ID/" + agentCode + "/" + id + "/");
  }

  getByCoverNoteNumber(agentCode: string, coverNoteNumber) {
    return this.authHttp.get<any>(this._Url + "GetModified" + "/GET/" + agentCode + "/" + "0" + coverNoteNumber + "/");
  }

  filterByCoverNoteNumber(agentCode: string, coverNoteNumber) {
    return this.authHttp.get<any>(this._Url + "GetModified" + "/FLR/" + agentCode + "/" + "0" + coverNoteNumber + "/");
  }


}
