var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ReceiptAllocationsObject } from '../_models/receipt_allocations';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
export class ReceiptAllocationsService {
    constructor(authHttp, auth) {
        this.authHttp = authHttp;
        this.auth = auth;
        this._Url = "/api/receiptAllocations/";
        this._genericUrl = "/api/generic/";
        this._controllerName = "receiptAllocation"; //entity for YdroApi
    }
    getAll() {
        return this.authHttp.get(this._genericUrl + this._controllerName + "/ALL" + "/");
    }
    getByID(aReceiptAllocationID) {
        return this.authHttp.get(this._genericUrl + this._controllerName + "/ID/" + aReceiptAllocationID + "/");
    }
    add(aReceiptAllocation) {
        return this.authHttp.post(this._genericUrl + "Modify/" + this._controllerName + "/I/", aReceiptAllocation);
    }
    update(aReceiptAllocationID, aReceipt) {
        return this.authHttp.post(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aReceiptAllocationID + "/", aReceipt);
    }
    delete(aReceiptAllocationID) {
        let aReceipt = new ReceiptAllocationsObject();
        return this.authHttp.post(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aReceiptAllocationID + "/", aReceipt);
    }
    deleteByReceiptID(aReceiptID) {
        let aReceipt = new ReceiptAllocationsObject();
        aReceipt.receiptID = aReceiptID;
        return this.authHttp.post(this._genericUrl + "Modify/" + this._controllerName + "/DFK/" + 0 + "/", aReceipt);
    }
    //unique
    getAllByReceipt(receiptId) {
        return this.authHttp.get(this._Url + "GetAllByReceipt/" + receiptId + "/");
    }
    syGetAccountUnAllocatedDocuments(accountCode) {
        return this.authHttp.get(this._Url + "SyGetAccountUnAllocatedDocuments/" + accountCode + "/");
    }
    syGetAccountUnAllocatedDocumentsNew(accountCode) {
        return this.authHttp.get(this._Url + "SyGetAccountUnAllocatedDocumentsNew/" + accountCode + "/");
    }
    exportReceiptToSy(receiptId) {
        return this.authHttp.get(this._Url + "exportReceiptToSy/" + receiptId + "/");
    }
    getTotalAllocationForReceipt(receiptId) {
        return this.authHttp.get(this._Url + "getTotalAllocationForReceipt/" + receiptId + "/");
    }
    getPendingReceiptsByAllocatedDocumentNumber(DocumentNumber) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.authHttp.get(this._Url + "getPendingReceiptsByAllocatedDocumentNumber/" + DocumentNumber + "/").toPromise();
        });
    }
    getAllCategories() {
        return this.authHttp.get(this._Url + "GetAllCategories/");
    }
}
