/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countdown-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./countdown-message.component";
import * as i5 from "../../_services/countdowmessagepopup.service";
var styles_CountdownMessageComponent = [i0.styles];
var RenderType_CountdownMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountdownMessageComponent, data: {} });
export { RenderType_CountdownMessageComponent as RenderType_CountdownMessageComponent };
function View_CountdownMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "popup-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " ", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Inactivity Detected!")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("You are about to be logged out due to inactivity!")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Logging out in")); var currVal_3 = _co.countdown; var currVal_4 = i1.ɵunv(_v, 13, 2, i1.ɵnov(_v, 15).transform("seconds...")); _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_CountdownMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountdownMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CountdownMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "countdown-message", [], null, null, null, View_CountdownMessageComponent_0, RenderType_CountdownMessageComponent)), i1.ɵdid(1, 114688, null, 0, i4.CountdownMessageComponent, [i5.CountdownMessagePopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountdownMessageComponentNgFactory = i1.ɵccf("countdown-message", i4.CountdownMessageComponent, View_CountdownMessageComponent_Host_0, {}, {}, []);
export { CountdownMessageComponentNgFactory as CountdownMessageComponentNgFactory };
