

export class PolicyItemObject {
    policyItemID: number = 0;
    itemID: number = 0;
    itemCode: string = ""; 
    coverNoteNumber: string = "";
    coverNoteID: number = 0; 
    policyID: number = 0;
    insuredAmount: number = 0;
    comments: string = "";
    certificatePrinted: number = 0;
    certificateNumber: string = "";
    coverNotePrinted: number = 0;
    coverNoteUse: string = "";
    status: string = "";
    creationDate: Date = new Date();
    createdBy: number = 0;
    lastUpdateDate: Date = new Date();
    lastUpdatedBy: number = 0;

}
