<!-- popup.component.html -->
<div *ngIf="this.isVisible" class="popup-overlay">

  <div class="popup-content">

    <!--header-->
    <div>
      <h5>{{ 'Inactivity Detected!' | translate}}</h5>
    </div>

    <hr />

    <!--Body-->
    <div>
      <p>{{ 'You are about to be logged out due to inactivity!' | translate }}</p>
      <p><i>{{ 'Logging out in' | translate}} {{ countdown }} {{'seconds...' | translate}}</i></p>
    </div>


  </div>
</div>


