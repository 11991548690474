

export class ClientObject {

    clientID: number;
    syClientSerial: number = 0;
    syClientCode: string = "";
    identityCard: string = "";
    generalAgentSySerial: number = 0;
    agentSySerial: number = 0;
    agentCode: string = "";
    accountType: string = "";
    salutation: string = "";
    firstName: string = "";
    longDescription: string = "";
    contactName: string = "";
    contactTitle: string = "";
    birthDate: Date = new Date();
    occupationSerial: number = 0;
    occupationSyCode: string = "";
    nationalitySerial: number = 0;
    nationalitySyCode: string = "";
    accountCode: string = "";
    consolidation: string = "";
    language: string = "";
    postalCode: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    streetNo: string = "";
    city: string = "";
    district: string = "";
    country: string = "";
    homeTelephone: string = "";
    workTelephone: string = "";
    mobile: string = "";
    teleFax: string = "";
    email: string = "";
    mailPostalCode: string = "";
    mailAddressLine1: string = "";
    mailAddressLine2: string = "";
    mailStreetNo: string = "";
    mailCity: string = "";
    mailDistrict: string = "";
    mailCountry: string = "";
    status: string = "";
    statusDescription: string = "";
    subAgentCode: string = "";
    creationDate: Date = new Date();
    createdBy: number = 0;
    lastUpdateDate: Date = new Date();
    lastUpdatedby: number = 0;
    syLastUpdateDate: Date = new Date();

    addressLine1_alt: string = "";
    addressLine2_alt: string = "";
    streetNo_alt: string = "";
    city_alt: string = "";
    district_alt: string = "";
    country_alt: string = "";
    mailAddressLine1_alt: string = "";
    mailAddressLine2_alt: string = "";
    mailStreetNo_alt: string = "";
    mailCity_alt: string = "";
    mailDistrict_alt: string = "";
    mailCountry_alt: string = "";

}
