

export class ReceiptObject {

  receiptID: number = 0;
  agentCode: string = "";
  creationDate: Date = new Date();
  createdBy: number = 0;
  documentDate: Date = new Date();
  lastUpdateDate: Date = new Date();
  lastUpdatedby: number = 0;
  receiptSyCode: string = "";
  userID: number = 0;
  receiptType: string = "";
  accountCode: string = "";
  amount: number = 0;
  status: string = "";
  syStatus: string = "";
  clientID: number = 0;
  comment: string = "";
  receiptDataReceiptID: number;
  chequeNumber: string = "";
  chequeName: string = "";
  chequeBankName: string = "";
  postDateTime: Date;
  impersonatorUserID: number;
  isPaid: boolean;
  IsPaidStatus: string;
  receiptNumber: string = "";
  receiptCode: string = "";
  isManual: boolean = false;
}
