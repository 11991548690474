
import { ItemObject } from '../_models/item';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class ItemService {
  private _Url: string = "/api/item/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "item";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aItemID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aItemID + "/");
  }


  add(aItem: ItemObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aItem);
  }

  update(aItemID: number, aItem: ItemObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aItemID + "/", aItem);
  }

  delete(aItemID: number) {
    let aItem: ItemObject = new ItemObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aItemID + "/", aItem)
  }

  //unique
  getByRegistrationLocalDB(registration: string) {
    return this.authHttp.get<any>(this._Url + "GetByRegistrationLocalDB/" + registration + "/");
  }

  getBySashiLocalDB(sashi: string) {
    return this.authHttp.get<any>(this._Url + "GetBySashiLocalDB/" + sashi + "/");
  }




}
