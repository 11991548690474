
import { ReceiptAllocationsObject } from '../_models/receipt_allocations';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';



@Injectable()
export class ReceiptAllocationsService {
  private _Url: string = "/api/receiptAllocations/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "receiptAllocation"; //entity for YdroApi

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

    }

  getAll() {  
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aReceiptAllocationID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aReceiptAllocationID + "/");
  }

  add(aReceiptAllocation:ReceiptAllocationsObject) {    
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I/", aReceiptAllocation);
  }

  update(aReceiptAllocationID: number, aReceipt: ReceiptAllocationsObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aReceiptAllocationID + "/", aReceipt);
  }

  delete(aReceiptAllocationID: number) {
    let aReceipt: ReceiptAllocationsObject = new ReceiptAllocationsObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aReceiptAllocationID + "/", aReceipt)
  }
  deleteByReceiptID(aReceiptID: number) {
    let aReceipt: ReceiptAllocationsObject = new ReceiptAllocationsObject();
    aReceipt.receiptID = aReceiptID;
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/DFK/" + 0 + "/", aReceipt)
  }

  //unique
  getAllByReceipt(receiptId: number) {
    return this.authHttp.get<any>(this._Url + "GetAllByReceipt/" + receiptId + "/");
  }

  syGetAccountUnAllocatedDocuments(accountCode: string) {
    return this.authHttp.get<any>(this._Url + "SyGetAccountUnAllocatedDocuments/" + accountCode + "/");
  }

  syGetAccountUnAllocatedDocumentsNew(accountCode: string) {
    return this.authHttp.get<any>(this._Url + "SyGetAccountUnAllocatedDocumentsNew/" + accountCode + "/");
  }

  exportReceiptToSy(receiptId: number) {
    return this.authHttp.get<any>(this._Url + "exportReceiptToSy/" + receiptId + "/");
  }

  getTotalAllocationForReceipt(receiptId: number) {
    return this.authHttp.get<any>(this._Url + "getTotalAllocationForReceipt/" + receiptId + "/");
  }

  async getPendingReceiptsByAllocatedDocumentNumber(DocumentNumber: string) {
    return this.authHttp.get<any>(this._Url + "getPendingReceiptsByAllocatedDocumentNumber/" + DocumentNumber + "/").toPromise();
  }

  getAllCategories() {
    return this.authHttp.get<any>(this._Url + "GetAllCategories/");
  }


}
