
export class BranchObject {

  BranchID: number = 0;
  Description: string = "";
  CreationDate: Date = new Date();
  CreatedBy: number = 0;
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
  BranchEmail: string = "";
  Status: string = "";
  Code: string = "";


}
