import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { DuobondClaims } from '../_models/duobondclaims';

@Injectable()
export class DuobondService {
  private _Url: string = "/api/duobond/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  getByRegistration(registration: string, policySySerial: number) {
    return this.authHttp.get<any>(this._Url + "GetByRegistration/REG/" + registration + "/" + policySySerial + "/");
  }

  add(aDuobondClaims: DuobondClaims) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/I/", aDuobondClaims);
  }

  update(aDuobondClaimID: number, aDuobondClaims: DuobondClaims) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/U/" + aDuobondClaimID + "/", aDuobondClaims);
  }

  addDuobondClaimFiles(policySySerial: number, fileObjectsList: any[] ) {
    return this.authHttp.post<any>(this._Url + "AddDuobondClaimFiles/" + policySySerial + "/", fileObjectsList);
  }

}
