/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./versionmodal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./versionmodal.component";
import * as i5 from "../../_services/notifications.service";
import * as i6 from "../../_services/receipt.service";
import * as i7 from "../../_services/userInfo.service";
import * as i8 from "../../_services/versionmodal.service";
var styles_VersionModalComponent = [i0.styles];
var RenderType_VersionModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionModalComponent, data: {} });
export { RenderType_VersionModalComponent as RenderType_VersionModalComponent };
export function View_VersionModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Version :"])), (_l()(), i1.ɵted(8, null, [" ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Updated At : ", ""])), i1.ɵppd(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["style", "padding-top:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "u", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "modal-btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "button", [["class", "btn btn-outline-default btn-round modal-close-btn"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).dialogRef.close(i1.ɵnov(_v, 20).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [["class", "material-icons modal-close-btn-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" close "])), (_l()(), i1.ɵeld(23, 0, null, null, 4, "div", [["class", "modal-btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 3, "button", [["class", "btn btn-outline-default btn-round modal-close-btn"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).dialogRef.close(i1.ɵnov(_v, 25).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "span", [["class", "material-icons modal-close-btn-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" close "]))], function (_ck, _v) { var currVal_6 = "button"; var currVal_7 = ""; _ck(_v, 20, 0, currVal_6, currVal_7); var currVal_10 = "button"; var currVal_11 = ""; _ck(_v, 25, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.versionDetails["Version"]; _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.versionDetails["UpdatedDate"], "dd-MM-yyyy")); _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.versionDetails["UpdateEntry"]; _ck(_v, 16, 0, currVal_2); var currVal_3 = _co.versionDetails["Description"]; _ck(_v, 17, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 20).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 20).type; _ck(_v, 19, 0, currVal_4, currVal_5); var currVal_8 = (i1.ɵnov(_v, 25).ariaLabel || null); var currVal_9 = i1.ɵnov(_v, 25).type; _ck(_v, 24, 0, currVal_8, currVal_9); }); }
export function View_VersionModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "versionmodal", [], null, null, null, View_VersionModalComponent_0, RenderType_VersionModalComponent)), i1.ɵdid(1, 4308992, null, 0, i4.VersionModalComponent, [i5.NotificationsService, i3.MatDialog, i1.ElementRef, i6.ReceiptService, i7.UserInfoService, i8.VersionModalService, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VersionModalComponentNgFactory = i1.ɵccf("versionmodal", i4.VersionModalComponent, View_VersionModalComponent_Host_0, {}, {}, []);
export { VersionModalComponentNgFactory as VersionModalComponentNgFactory };
