/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./components-global/countdown-message/countdown-message.component.ngfactory";
import * as i4 from "./components-global/countdown-message/countdown-message.component";
import * as i5 from "./_services/countdowmessagepopup.service";
import * as i6 from "@angular/common";
import * as i7 from "./app.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./_services/authentication.service";
import * as i10 from "./_services/idle.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "countdown-message", [], null, null, null, i3.View_CountdownMessageComponent_0, i3.RenderType_CountdownMessageComponent)), i1.ɵdid(3, 114688, null, 0, i4.CountdownMessageComponent, [i5.CountdownMessagePopupService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "button", [["class", "scroll-to-top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "show-scroll": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" north "])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "button", [["class", "scroll-to-bottom"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToBottom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "show-scroll": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" south "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = "scroll-to-top"; var currVal_1 = _ck(_v, 8, 0, _co.showScrollTop); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = "scroll-to-bottom"; var currVal_3 = _ck(_v, 14, 0, _co.showScrollBottom); _ck(_v, 13, 0, currVal_2, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.TranslateService, i9.AuthenticationService, i10.IdleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
