
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ClientGroupsObject } from '../_models/clientgroups';


@Injectable()
export class ClientService {
  private _Url: string = "/api/client/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "client";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

 //all methods are unique

  getAll() {
    return this.authHttp.get<any>(this._Url );
  }

  getByID(aClientID: number) {
    return this.authHttp.get<any>(this._Url + "GetByID/" + aClientID + "/");
  }


  add(aClient: ClientObject) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/I/", aClient);
  }

  update(aClientID: number, aClient: ClientObject) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/U/" + aClientID + "/", aClient);
  }

  delete(aClientID: number) {
    let aClient: ClientObject = new ClientObject();
    return this.authHttp.post<any>(this._Url + "Modify" + "/D/" + aClientID + "/", aClient)
  }

  getByIdentity(identity: string) {    
    return this.authHttp.get<any>(this._Url + "GetByIdentity/" + encodeURIComponent(identity) + "/");
  }

  filterByIdentity(identity: string) {
    return this.authHttp.get<any>(this._Url + "FilterByIdentity/" + encodeURIComponent(identity) + "/");
  }

  uploadClientGroupFile(objectToSend: any) {

    return this.authHttp.post<any>(this._Url + "UploadClientGroupFile" + "/", objectToSend);

  }

  addClientGroup(clientGroupsObject: ClientGroupsObject) {


    return this.authHttp.post<any>(this._Url + "ModifyClientGroup" + "/I/0/", clientGroupsObject);
  }

  deleteClientGroup(clientGroupId: number) {
    return this.authHttp.get<any>(this._Url + "ModifyClientGroup" + "/D/" + clientGroupId + "/0/");
  }

  getClientGroupsByUserID() {
    return this.authHttp.get<any>(this._Url + "GetClientGroupsByUserID/USR/");
  }

  getAllClientGroupsExceptions() {
    return this.authHttp.get<any>(this._Url + "GetAllClientGroupsExceptions/EXC/");
  }
}
