import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { DuobondClaims } from '../_models/duobondclaims';

@Injectable()
export class ClaimsService {
  private _Url: string = "/api/claims/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  getClaimRelatedCodes(category: string, searchValue: string ) {
    return this.authHttp.get<any>(this._Url + "GetClaimRelatedCodes/" + category + "/" + searchValue + "/");
  }

}
