

export class ReceiptAllocationsObject {

  receiptAllocationsID: number = 0;
  receiptID: number = 0;
  allocatedDocumentCode: string = "";
  allocatedDocumentNumber: string = "";
  allocatedAmount: number = 0;
  totalAllocationAmount: number = 0;
  documentNumber: string = "";
  documentDate: Date = new Date();
  policyInformation: string = "";
  policyNumber: string = "";
  creationDate: Date = new Date();
  createdBy: number = 0;
  lastUpdateDate: Date = new Date();
  lastUpdatedby: number = 0;
  installmentAnalysis: string = "";
  status: string = "";
  discountAmount: number = 0;
  transferAmount: number = 0;
  ifrs17Code: string = '';
  prepaymentComments: string = '';

}
