

export class DriverObject {

    driverID: number = 0;
    driverSySerial: number = 0;
    identityCard: string = "";
    firstName: string = "";
    surName: string = "";
    gender: string = "";
    occupationSyCode: string = "";
    nationalitySyCode: string = "";
    agentSerial: number = 0;
    agentCode: string = "";
    birthDate: Date = new Date();
    learnerLicenseDate: Date = new Date();
    visitor: boolean = false;
    infDefVisionHearing: boolean = false;
    comments: string = "";
    permitDate: Date = new Date();
    secondPermitDate: Date = new Date();
    renewedLicenseDate: Date = new Date();
    learnerLicenseExpiryDate: Date = new Date();
    licenseIssueCountry: number = 0;
    secondLicenceIssueCountry: number = 0;
    postalCode: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    streetNo: string = "";
    city: string = "";
    district: string = "";
    country: string = "";
    homeTelephone: string = "";
    workTelephone: string = "";
    mobile: string = "";
    teleFax: string = "";
    email: string = "";
    status: string = "";
    statusDescription: string = "";
    creationDate: Date = new Date();
    createdBy: number = 0;
    lastUpdateDate: Date = new Date();
    lastUpdatedby: number = 0;

}
