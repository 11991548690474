

export class PolicyPermissionObject {

    policyPermissionID: number = 0;
    userID: number = 0;
    approval_Level: number = 0;
    alter_Level: number = 0;
    branchRoleID: number = 0;
    status: string = "";
    creationDate: Date = new Date();
    createdBy: number = 0;
    lastUpdateDate: Date = new Date();
    lastUpdatedBy: number = 0;

}
