export class PolicyPermissionLineObject {

  policyPermissionLineID: number = 0;
  policyPermissionID: number = 0;
  gcInsuranceTypeID: number = 0;
  status: string = "";
  approvalLevel: number = 0;
  new: boolean = false;
  modify: boolean = false
  delete: boolean = false
  lock: boolean = false
  unlock: boolean = false
  post: boolean = false
  printScheduleOriginal: boolean = false
  printScheduleCopy: boolean = false
  printCertificateOriginal: boolean = false
  printCertificateCopy: boolean = false
  creationDate: Date = new Date();
  createdBy: number = 0;
  lastUpdateDate: Date = new Date();
  lastUpdatedby: number = 0;
}
