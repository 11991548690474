import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { Observable ,Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { UsersService } from './users.service';
import { User } from '../_models';
import { ModulesAccessService } from './modules_access.service';
import { promise } from 'protractor';
import { escapeLeadingUnderscores } from 'typescript';

export interface UserInfoModel {
  username: string;
  messageCount: number;
}

//@Injectable({ providedIn: 'root' })
@Injectable()
export class UserInfoService  {

  private _cachedUserInfo: UserInfoModel;


  //user Data
  private loggedInUser: User;
  public userName = '<empty>';
  public userMessagesNum = 0;
  private isAdmin = false;
  private isAgent = false;
  private isDuobond = false;
  private isLifeline = false;
  private isOXS = false;
  private isManualReceiptsRegisterUser = false;
  private userImpersonations = <any>[];
  private loggedInUserModulesAccess = [];
  private loggedInUserGroupsModulesAccess = [];

  private LoggedInUserSubject: BehaviorSubject<User>;
  public loggedInUserObs: Observable<User>;

  private impersonationAgentSubject: BehaviorSubject<User>;
  public impersonationAgentObs: Observable<User>;

  private ImpersonationsSubject: BehaviorSubject<any[]>;
  public ImpersonationsObs: Observable<any[]>;

  private LoggedInDataComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public LoggedInDataCompleteObs: Observable<boolean>;

  //main menu
  public showMainMenuUsers = false;


  constructor(private auth: AuthenticationService
    , private authHttp: HttpClient
    , private usersService: UsersService
    , private modulesAccessService: ModulesAccessService
  ) {    
    this.ImpersonationsSubject = new BehaviorSubject<User[]>([]);
    this.LoggedInDataCompleteObs = this.LoggedInDataComplete.asObservable();    
  }

  public async setLoggedInUser_working() {


    if (this.auth.currentUserValue && !this.auth.isTokenExpired) {
      this.loggedInUser = await this.usersService.getDatabaseUserByUserName(this.auth.currentUserValue["username"]).toPromise();


      this.LoggedInUserSubject = new BehaviorSubject<User>(this.loggedInUser);
      this.loggedInUserObs = this.LoggedInUserSubject.asObservable();


      this.loggedInUserModulesAccess = await this.modulesAccessService.getByUserID(this.loggedInUser[0]["UserID"]).toPromise();


      this.loggedInUserGroupsModulesAccess = await this.modulesAccessService.getByUserIDGroups(this.loggedInUser[0]["UserID"]).toPromise();

      this.userImpersonations = await this.getUserImpersonations().toPromise();

      this.ImpersonationsSubject = new BehaviorSubject<User[]>(this.userImpersonations);
      this.ImpersonationsObs = this.ImpersonationsSubject.asObservable();

      this.LoggedInDataComplete.next(true);


    }
  }

  public setLoggedInUser() {
    
    if (this.auth.currentUserValue && !this.auth.isTokenExpired) {
      if (!this.LoggedInDataComplete.value) {        
        return this.usersService.getDatabaseUserByUserName(this.auth.currentUserValue["username"]).pipe(
          map(res => {
            
            this.loggedInUser = res
            this.LoggedInUserSubject = new BehaviorSubject<User>(this.loggedInUser);
            this.loggedInUserObs = this.LoggedInUserSubject.asObservable();
          }
          ),
          switchMap(res => this.getSession("impersonated_username")),
          switchMap(res => this.usersService.getDatabaseUserByUserName(res).pipe(map(res => {
            this.impersonationAgentSubject = new BehaviorSubject<User>(res[0]);
            this.impersonationAgentObs = this.impersonationAgentSubject.asObservable();
          }))),
          switchMap(res => this.modulesAccessService.getByUserID(this.loggedInUser[0]["UserID"]).pipe(map(mas => this.loggedInUserModulesAccess = mas))),
          switchMap(res => this.modulesAccessService.getByUserIDGroups(this.loggedInUser[0]["UserID"]).pipe(map(ugma => this.loggedInUserGroupsModulesAccess = ugma))),
          switchMap(res => this.getUserImpersonations().pipe(map(imp => {
            this.userImpersonations = imp
            this.ImpersonationsSubject = new BehaviorSubject<User[]>(this.userImpersonations);
            this.ImpersonationsObs = this.ImpersonationsSubject.asObservable();
            this.LoggedInDataComplete.next(true);            
          }))),
        )
      } else {        
        return EMPTY;
      }
    } else {      
      return EMPTY;
    }
  }



  get isLoggedInDataComplete() {
    return this.LoggedInDataComplete.value;
  }

  get isLoggedInDataCompleteObs() {
    return this.LoggedInDataCompleteObs;
  }

  get getLoggedInUser(): User {
    

    if (this.auth.currentUserValue && !this.auth.isTokenExpired && this.LoggedInDataComplete.value) {
      return this.LoggedInUserSubject.value;
    }
    
  }
  
  get getImpersonationAgentCode(): User {
    if (this.auth.currentUserValue && !this.auth.isTokenExpired && this.LoggedInDataComplete.value) {
      return this.impersonationAgentSubject.value;
    } 
  }
  
  set setImpersonationAgentCode(impersonationAgent: User) {
    this.impersonationAgentSubject.next(impersonationAgent);

  }

  get getLoggedInUserAccessModules() {

    return this.loggedInUserModulesAccess;
  }

  get getLoggedInUserGroupsAccessModules() {
   
    return this.loggedInUserGroupsModulesAccess;
  }

  get getLoggedInUserImpersonationsObs() {
    return this.ImpersonationsObs;
  }
  get getLoggedInUserImpersonations() {
    return this.ImpersonationsSubject.value;
  }

  get isAdministrator() {
    if (this.auth.isLoggedIn && this.loggedInUser) {
      if (this.loggedInUser[0]["IsAdministrator"]) {
     
        return true;
      }
      else
        return false;
    } else
      return false;
  }


  public clearLoggedInUserData() {
    this.loggedInUser = null;
    this.loggedInUserModulesAccess = [];
    this.loggedInUserGroupsModulesAccess = [];
    this.userImpersonations = [];

    if (this.LoggedInDataComplete != null)
      this.LoggedInDataComplete.next(null);

    if (this.LoggedInUserSubject != null)
      this.LoggedInUserSubject.next(null);

    this.ImpersonationsSubject.next([]);
    
  }

  public allowNavigate(moduleRoute: string) {
    
    if (this.auth.isLoggedIn && this.loggedInUser) {

      if (this.getLoggedInUser[0]["IsAdministrator"])
        return true;

      if (moduleRoute.endsWith("LIST")) {
        moduleRoute = moduleRoute.replace("LIST", "");
      }
      if (moduleRoute.endsWith("MENUMAIN")) {
        moduleRoute = moduleRoute.replace("MENUMAIN", "");
      }
      var pAllow = false

      //Check access based on user groups
      if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && (access.AllowView || access.AllowModify || access.AllowDelete)) >= 0) {
        pAllow = true;
      }


      // Overwrite Group access with the user specific access rights
      if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && (access.AllowView || access.AllowModify || access.AllowDelete)) >= 0) {
        pAllow = true;
      }


      return pAllow;
    }
  }

  public allowView(moduleRoute: string) {

    if (this.auth.isLoggedIn && this.loggedInUser) {
      if (this.loggedInUser[0]["IsAdministrator"])
        return true;

      var pAllow = false

      //Check access based on user groups
      if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowView) >= 0) {
        pAllow = true;
      }

      // Overwrite Group access with the user specific access rights
      if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowView) >= 0) {
        pAllow = true;
      }


      return pAllow;
    }
  }

  public allowModify(moduleRoute: string) {

    if (this.auth.isLoggedIn && this.loggedInUser) {
      if (this.loggedInUser[0]["IsAdministrator"])
        return true;

      var pAllow = false

      //Check access based on user groups
      if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowModify) >= 0) {
        pAllow = true;
      }

      // Overwrite Group access with the user specific access rights
      if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowModify) >= 0) {
        pAllow = true;
      }
      return pAllow;
    }
  }

  public allowDelete(moduleRoute: string) {

    if (this.auth.isLoggedIn && this.loggedInUser) {
      if (this.loggedInUser[0]["IsAdministrator"])
        return true;

      var pAllow = false

      //Check access based on user groups
      if (this.loggedInUserGroupsModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowDelete) >= 0) {
        pAllow = true;
      }

      // Overwrite Group access with the user specific access rights
      if (this.loggedInUserModulesAccess.findIndex(access => access.ModuleCode == moduleRoute && access.AllowDelete) >= 0) {
        pAllow = true;
      }

      return pAllow;
    }
  }

  public getUserImpersonations() {
    if (this.auth.currentUserValue) {
      return this.authHttp.get('api/user/GetUserImpersonations');
    }
  }

  public setLoggedInUserSessionInfo() {

    if (this.auth.isLoggedIn) {
      return this.authHttp.get('api/user/setLoggedInUserSessionInfo');
    }

  }

  //public async setLoggedInUserSessionInfo() {


  //  if (this.auth.isLoggedIn) {
  //    return this.authHttp.get('api/user/setLoggedInUserSessionInfo').toPromise();
  //    }

  //}

  setSession(sessionName: string, sessionValue: string) {
 
    var value: string = sessionValue.toString();
    //if (value.indexOf('.') > 0) {
    //  value = value.replace(/\./g, '*');
    //}    

    //return;


    return this.authHttp.get("api/user/setSessionValue/" + encodeURIComponent(sessionName) + "/" + encodeURIComponent(value));
  }

  getSession(sessionName) {

    return this.authHttp.get("api/user/getSessionValue/" + sessionName.toString());
  }


   startNotificationsService = () => {
    this.authHttp.get('api/notifications/')
      .subscribe(res => {
     
      })
  }

}
