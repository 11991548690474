

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
//import { ResponseContentType } from '@angular/http';


@Injectable()
export class ReportsService {
  private _Url: string = "/api/reports/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "reports";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  //unique
  public GetOutStandingInstallmentsReportData(AsAtDate: string, ToPeriod: number, onlyDD: boolean, ClientAccountCode: string) { 
    return this.authHttp.get<any>(this._Url + "GetOutStandingInstallmentsReportData/" + AsAtDate + "/" + ToPeriod + "/" + onlyDD + "/" + ClientAccountCode);
  }

  public GetReportBase64(recreate: boolean, Filename: string, ReportName: string, ReportParameters: string, Dataset: object) {

    if (ReportParameters == "") {
      ReportParameters = null;
    }

    var dataset;
    if (Dataset == null || Dataset == undefined)
      dataset = JSON.parse("[]");
    else {
      dataset = Dataset;
      //dataset = Dataset;
    }

    return this.authHttp.post<any>(this._Url + "GetReportBase64/" + recreate + "/" + Filename + "/" + ReportName + "/" + encodeURIComponent(ReportParameters) + "/", dataset);
  }

  public GetReportJsonBase64(recreate: boolean, Filename: string, ReportName: string, addAgentAndSubCodes: boolean, customBackendParams: boolean, Result: object) {
    return this.authHttp.post<any>(this._Url + "GetReportJsonBase64/" + recreate + "/" + Filename + "/" + ReportName + "/" + addAgentAndSubCodes + "/" + customBackendParams + "/", Result);
  }

  //public GetReport(ReportTypeFolder: string, Filename: string, ReportParameters: string, ReportName: string) {
  //  var result;
  //  return this.authHttp.get(this._Url + "/GetReport/" + ReportTypeFolder + "/" + Filename + "/" + ReportParameters + "/" + ReportName + "/", { responseType: ResponseContentType.Blob })
  //    .pipe(map((res) => {
  //      return new Blob([res.blob()], {
  //        type: 'application/pdf'
  //      })
  //    }))
  //    .subscribe(response => {
  //      result = response;
  //    },
  //      error => {
  //        console.log(error);
  //      },
  //      () => {
  //        var blob = new Blob([result], { type: 'application/pdf' });
  //        var filename = Filename + '.pdf';
  //        var fileSaver = require('file-saver');
  //        fileSaver.saveAs(blob, filename);
  //      });
  //}


  public sendReportEmail(sendEmailObject: object) {
    return this.authHttp.post<any>(this._Url + "SendReportEmail/", sendEmailObject);
  }

  //incometaxcertificate1101
  public getReportData(reportAPIName:string ,model: Object) {
    return this.authHttp.post<any>(this._Url + "GetReportData/" + reportAPIName + "/", model);
  }


}
