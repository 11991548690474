import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountdownMessageComponent } from './components-global/countdown-message/countdown-message.component';
import { AuthenticationService } from './_services/authentication.service';
import { IdleService } from './_services/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  showScrollTop: boolean;
  showScrollBottom: boolean = true;
  showScrollHeight = 300;
  hideScrollHeight = 10;

  title = 'app';

  constructor(public translate: TranslateService,
    public authenticationService: AuthenticationService,
    public idleService: IdleService
  ) { }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const scrollBottom = scrollHeight - clientHeight - scrollTop;
    if (scrollTop > this.showScrollHeight) {
      this.showScrollTop = true;
    } else if (this.showScrollTop && scrollTop < this.hideScrollHeight) {
      this.showScrollTop = false;
    }

    if (scrollBottom > this.showScrollHeight) {
      this.showScrollBottom = true;
    } else if (this.showScrollBottom && scrollBottom < this.hideScrollHeight) {
      this.showScrollBottom = false;
    }
  }


  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        //window.requestAnimationFrame(smoothscroll);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        // window.scrollTo(0, 0);//currentScroll - (currentScroll / 2)
      }
    })();
  }
  scrollToBottom() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      if (currentScroll < maxScroll) {
        //window.requestAnimationFrame(smoothscroll);
        window.scrollTo({ top: maxScroll, behavior: 'smooth' });
      }
    })();
  }

  ngOnInit() {

    //get the local storage language
    var localLanguage = localStorage.getItem("localLanguage");


    if (localLanguage != undefined && localLanguage != null) {
      this.translate.use(localLanguage);
      this.translate.setDefaultLang(localLanguage);
    }
    else {
      this.translate.use('en');
      this.translate.setDefaultLang('en'); //default
    }

    //call the startIdle when the user is logged in only!
    if (this.authenticationService.isLoggedIn) {
      //this.idleService.setupInactivityDetection();
    }

  }


}
