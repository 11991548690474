import { Component, HostBinding, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { NavItem } from '../../_models/nav-item';
import { Router } from '@angular/router';
import { GenericService, ReceiptService, ToasterPosition, ToasterType, UserInfoService } from '../../_services';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { CommunicationService } from '../../_services/communication.service';
import { NAVITEMS } from '../../sidebar/sidebar.component';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  notificationService: any;

  navItems: NavItem[] = NAVITEMS;

  constructor(
    public router: Router
    , private elementRef: ElementRef
    , private renderer: Renderer2
    , public usrInfo: UserInfoService
    , public translate: TranslateService
    , public receiptsService: ReceiptService
    , private genericService: GenericService
    , private communicationService: CommunicationService
    , public dialog: MatDialog) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {


  }

  public ngAfterViewInit(): void {
    const listItems = this.elementRef.nativeElement.querySelectorAll('.mat-list-item-content') as HTMLElement[];
    listItems.forEach(listItem => {
      this.renderer.setStyle(listItem, 'width', '100%');
      this.renderer.setStyle(listItem, 'height', 'auto');
    });
  }

  onItemSelected(item: NavItem) {
    
    if (item.button) {
      if (item.functionToCall == 'receiptsAutoProcess') {
        this.receiptsAutoProcess();
      }
      else if (item.functionToCall == 'sendDirectDebitEmail') {
        this.sendDirectDebitEmail();
      }
      return;
    }
    if (!item.children || !item.children.length) {
      if (item.route == "covernoteissue") {
        (window as any).open("https://yperainsurance.info/", "_blank");
      }
      else if (item.route == "travelinsurance") {
        (window as any).open("https://ydrogios.soeasytravelinsurance.com/", "_blank");
      }
      else {
        if (item.gParams != undefined && item.gParams != null && item.gParams != '') {
          this.router.navigate([item.route, { gParams: item.gParams }]);
        }
        else {
          this.router.navigate([item.route]);
        }

      }

    }



    if (item.children && item.children.length) {
      //Collapse all
      this.navItems.forEach((navItem) => {
        if (navItem.expanded != undefined && navItem != item) {
          navItem.expanded = false;
        }
      })

      //Expand only current item
      item.expanded = !item.expanded;
    }
  }
  receiptsAutoProcess() {
    //show pop up
    const dialogData = new ConfirmDialogModel(this.genericService.translateData("Confirm Action"), this.genericService.translateData("Are you sure you want to continue with receipts auto process?"));
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.receiptsService.autoReceiptsProcedure().subscribe(res => {


        });
      }

    });

  }
  sendDirectDebitEmail() {
    //show pop up
    const dialogData = new ConfirmDialogModel(this.genericService.translateData("Confirm Action"), this.genericService.translateData("Proceed to send Direct Debit email?"));
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var emailBody = `<h3><strong><u>Παρακαλώ όπως προχωρήσετε σε όλες τις ενέργειες σας όσον αφορά συμβόλαια με Direct Debit για να ακολουθήσει Export.</u></strong></h3>`;
        var emaildata = {
          Subject: "Direct Debit",
          Body: emailBody,
          Recipients: "a.savva@ypera.com.cy",
          CC: "",
          BCC: "",
          senderEmail: "a.savva@ypera.com.cy",
          senderName: "Test",
        }

        this.communicationService.sendSingleEmail(emaildata).subscribe((data) => {
        
        });                
      }

    });
  }
}
