<router-outlet></router-outlet>
<countdown-message></countdown-message>
<div >
  <!--Scroll to top-->
  <button [ngClass]="{'show-scroll': showScrollTop}" (click)="scrollToTop()" class="scroll-to-top">
    <span class="material-icons">
      north
    </span>
  </button>
  <!--Scroll to Bottom-->
  <button [ngClass]="{'show-scroll': showScrollBottom}" (click)="scrollToBottom()" class="scroll-to-bottom">
    <span class="material-icons">
      south
    </span>
  </button>
</div>
