
import { GenericCodesObject } from '../_models/generic_codes';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class GenericCodesService {
  private _Url: string = "/api/genericCode/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "genericCode";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }
  
  getByID(aGenericCodesID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aGenericCodesID + "/");
  }


  add(aGenericCodes: GenericCodesObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aGenericCodes);
  }

  update(aGenericCodesID: number, aGenericCodes: GenericCodesObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aGenericCodesID + "/", aGenericCodes);
  }

  delete(aGenericCodesID: number) {
    let aGenericCodes: GenericCodesObject = new GenericCodesObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aGenericCodesID + "/", aGenericCodes)
  }

  //unique
  GetAllTypes() {
    return this.authHttp.get<any>(this._Url + "GetAllTypes" + "/");
  }

  GetByType(type: string) {
    return this.authHttp.get<any>(this._Url + "GetByType/" + type + "/");
  }

  GetByTypeQuery(type: string) {
    return this.authHttp.get<any>(this._Url + "GetByTypeQuery?type=" + type);
  }

}
