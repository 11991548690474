
export class PolicyCoverNotesObject {

  PolicyCoverNoteID: number = 0;
  CoverNoteSySerial: number = 0;
  CoverNoteNumber: string = "";
  ItemCode: string = "";
  AgentCode: string = "";
  IssueDate: Date = new Date();
  EffectiveDate: Date = new Date();
  ExpiryDate: Date = new Date();
  Conditions: string = "";
  Comments: string = "";
  Status: string = "";
  StatusDescription: string = "";
  CreationDate: Date = new Date();
  CreatedBy: number = 0;
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
}
