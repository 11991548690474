import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GenericService, NotificationsService, ToasterType, ToasterPosition, CommonService, SyClientsService, AuthenticationService, ReceiptService, UserInfoService, VersionModalService } from '../../_services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatCheckboxChange } from '@angular/material';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';

//new excel 
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'versionmodal',
  templateUrl: 'versionmodal.component.html',
  styleUrls: ['./versionmodal.component.css']
})


export class VersionModalComponent implements OnInit {


  versionDetails = [];

  constructor(
    private notificationService: NotificationsService,
    public dialog: MatDialog,
    public el: ElementRef,
    private _receiptService: ReceiptService,
    private usrInfo: UserInfoService,
    private versionModalService: VersionModalService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    //this.loadData()

  }


  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {

  }

  loadData() {

    this.versionModalService.getLatestVersion().subscribe(data => {

      this.versionDetails = data[0];

    });
      
}




}


