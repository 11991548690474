
  <div class="sidebar-wrapper" [ngStyle]="{'background-color' : DBName=='YDROTEST' || DBName=='YDROTST2' ? 'lightblue' : ''}">
    <div class="logo">
      <a href="http://www.ydrogios.com.cy/" class="simple-text logo-mini">
        <div class="logo-image-small">
          <img src="assets/img/YPERA_logo_transparent.png">
        </div>
      </a>
      <a href="http://www.ydrogios.com.cy/" class="simple-text logo-normal">
        Ypera Website 
      </a>
    </div>
   
    <p *ngIf="DBName=='YDROTEST' || DBName=='YDROTST2'" class="navbar-brand" style="color:purple; font-size:30px; font-weight:bold; margin-left: 10px"> ({{DBName}})</p>
    <mat-nav-list *ngIf="this.authService.isLoggedIn && this.usrInfo.isLoggedInDataComplete">
      <app-menu-list-item  *ngFor="let item of navItems" [item]="item">
      </app-menu-list-item>
    </mat-nav-list>

    <!--<ul class="nav" *ngIf="this.authService.isLoggedIn">
    <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
      <a [routerLink]="[menuItem.path]">
        <i class="nc-icon {{menuItem.icon}}"></i>
        <p>{{menuItem.title}}</p>
      </a>
    </li>
  </ul>-->
    <!--<li *ngIf="auth.loggedIn() && (_userInfo.inAdminGroup())">
    <a (click)="runUpdateDailyReport()" onmouseover="" style="cursor: pointer;">Update Daily Totals Report <i class="fa fa-spinner fa-spin" *ngIf="_runningUpdate==1"></i></a>
  </li>-->



  </div>
