

export class GenericCodesObject {

    genericCodeID: number = 0;
    code: string = "";
    description: string = "";
    type: string = "";

    status: string = "";
    statusDescription: string = "";

    string1: string = "";
    string1Label: string = "";
    string2: string = "";
    string2Label: string = "";
    string3: string = "";
    string3Label: string = "";
    string4: string = "";
    string4Label: string = "";
    string5: string = "";
    string5Label: string = "";

    number1: number = 0;
    number1Label: string = "";
    number2: number = 0;
    number2Label: string = "";
    number3: number = 0;
    number3Label: string = "";
    number4: number = 0;
    number4Label: string = "";
    number5: number = 0;
    number5Label: string = "";

    date1: Date = new Date();
    date1Label: string = "";
    date2: Date = new Date();
    date2Label: string = "";
    date3: Date = new Date();
    date3Label: string = "";
    date4: Date = new Date();
    date4Label: string = "";
    date5: Date = new Date();
    date5Label: string = "";

    createdBy: number = 0;
    creationDate: Date = new Date();
    lastUpdateDate: Date = new Date();
    lastUpdatedBy: number = 0;
    
}
