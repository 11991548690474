
import { PostingReceiptRequestObject } from '../_models/postingreceiptrequest';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PostingReceiptRequestService {
  private _Url: string = "/api/postingReceiptRequest/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "postingReceiptRequest";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

   //unique
  getAll() {
    return this.authHttp.get<any>(this._Url);
  }

  getAllByStatus(aStatus: string) {
    return this.authHttp.get<any>(this._Url + "GetAllByStatus/" + aStatus + "/");
  }

  getByID(aPostingReceiptRequestID: number) {
    return this.authHttp.get<any>(this._Url + "GetByID/" + aPostingReceiptRequestID + "/");
  }

  getByReceiptID(aPostingReceiptRequestID: number) {
    return this.authHttp.get<any>(this._Url + "GetByReceiptID/" + aPostingReceiptRequestID + "/");
  }

  addPRR(aPostingReceiptRequest: PostingReceiptRequestObject) {
    return this.authHttp.post<any>(this._Url + "Create", aPostingReceiptRequest + "/");
  }


  //generic
  add(aPostingReceiptRequest: PostingReceiptRequestObject) {
    return this.authHttp.post<any>(this._Url + "Modify/" + "I" + "/0", aPostingReceiptRequest);
  }

  update(aPostingReceiptRequestID: number, aPostingReceiptRequest: PostingReceiptRequestObject) {
    return this.authHttp.post<any>(this._Url + "Modify/"  + "U/" + aPostingReceiptRequestID + "/", aPostingReceiptRequest);
  }

  delete(aPostingReceiptRequestID: number) {
    let aPostingReceiptRequest: PostingReceiptRequestObject = new PostingReceiptRequestObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aPostingReceiptRequestID + "/", aPostingReceiptRequest)
  }





}
