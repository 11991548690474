import { Injectable, EventEmitter, OnInit, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, timer, throwError } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../_models';

import * as CryptoJS from 'crypto-js';


//@Injectable({ providedIn: 'root' })
@Injectable()
export class ProtectorService implements OnInit {



  constructor(
  ) {        

  }

  public ngOnInit(): void {

  }

  //encryptionTypescript(msg) {
  //  // random salt for derivation       --
  //  var keySize = 256;
  //  var pass = 'WePgLK4*f!_3Vb#LDSsw$%*jJ';
  //  var salt = CryptoJS.lib.WordArray.random(16);
  //  // well known algorithm to generate key
  //  var key = CryptoJS.PBKDF2(pass, salt, {
  //    keySize: keySize / 32,
  //    iterations: 1000
  //  });
  //  // random IV
  //  var iv = CryptoJS.lib.WordArray.random(128 / 8);
  //  // specify everything explicitly
  //  var encrypted = CryptoJS.AES.encrypt(msg, key, {
  //    iv: iv,
  //    padding: CryptoJS.pad.Pkcs7,
  //    mode: CryptoJS.mode.CBC
  //  });

  //  // combine everything together in base64 string
  //  var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
    
  // // replace + with ()  , /+/g is used to replace all + symbols otherwise it will only replace the fi rst one
  //  var re = /\+/gi;
  //  var replaceResult = result.replace(re, '(');

  //  return replaceResult;
  //}


  encryptionTypescript(msg) {
    var keySize = 256;
    var pass = 'WePgLK4*f!_3Vb#LDSsw$%*jJ';
    var salt = CryptoJS.lib.WordArray.random(128/8);

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: 100
    });


    var iv = CryptoJS.lib.WordArray.random(128 / 8);


    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext)); 
    return result;
  }

  


  decrypt(transitmessage) {
    var keySize = 256;
    var pass = 'WePgLK4*f!_3Vb#LDSsw$%*jJ';

    var tst = CryptoJS.enc.Base64.parse(transitmessage);    
    var salt = CryptoJS.enc.Hex.parse(tst.toString().slice(0, 32));
    var iv = CryptoJS.enc.Hex.parse(tst.toString().slice(32, 64));

    var encrypted = CryptoJS.enc.Hex.parse(tst.toString().slice(64));
    
    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: 100
    });

    var decrypted = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.stringify(encrypted), key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    })
   
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


}
