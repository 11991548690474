
import { ManualReceiptRegisterObject } from '../_models/manual_receipt_register';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class ManualReceiptRegisterService {

  private _Url: string = "/api/manualReceiptRegister/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "manualReceiptRegister";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

    }

  //generic
  getAll() {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ALL" + "/");
  }

  getByID(aManualReceiptRegisterID: number) {
    return this.authHttp.get<any>(this._genericUrl + this._controllerName + "/ID/" + aManualReceiptRegisterID + "/");
  }


  add(aManualReceiptRegister: ManualReceiptRegisterObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/I" + "/", aManualReceiptRegister);
  }

  update(aManualReceiptRegisterID: number, aManualReceiptRegister: ManualReceiptRegisterObject) {
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/U/" + aManualReceiptRegisterID + "/", aManualReceiptRegister);
  }

  delete(aManualReceiptRegisterID: number) {
    let aManualReceiptRegister: ManualReceiptRegisterObject = new ManualReceiptRegisterObject();
    return this.authHttp.post<any>(this._genericUrl + "Modify/" + this._controllerName + "/D/" + aManualReceiptRegisterID + "/", aManualReceiptRegister)
  }

  //unique
  getAllWithUserID() {
    return this.authHttp.get<any>(this._Url + "GetAllWithUserID" + "/ALL" + "/");
  }

  getByIDWithUserID(aManualReceiptRegisterID: number) {
    return this.authHttp.get<any>(this._Url + "GetByIDWithUserID" + "/ID/" + aManualReceiptRegisterID + "/");
  }


    //unique
  checkValidityByUserID(aObjectID: number) {    
    return this.authHttp.get<any>(this._Url + "CheckValidityByUserID" + "/" + aObjectID );
  }

}
