

export class PoliciesObject {

    policyID: number = 0;
    policySySerial: number = 0;
    policySyNumber: string = "";
    policyTempNumber: string = "";
    status: string = "";
    syStatus: string = "";
    processStatus: string = "";
    syProcessStatus: string = "";
    gcInsuranceTypeID: number = 0;
    agentCode: string = "";
    clientID: number = 0;
    language: string = "";
    startingDate: Date = new Date();
    periodStartingDate: Date = new Date();
    proposedDate: Date = new Date();
    expiryDate: Date = new Date();
    financialPeriod: number = 0;
    financialYear: number = 0;
    cover: string = "";
    templateSySerial: number = 0;
    vehicleFleet: string = "";
    certificateSySerial: number = 0;
    occupationSySerial: number = 0;
    occupationSyCode: string = "";
    templateSyCode: string = "";
    certificateSyCode: string = "";

    creationDate: Date = new Date();
    createdBy: number = 0;
    lastUpdateDate: Date = new Date();
    lastUpdatedBy: Date = new Date();

}
