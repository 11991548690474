import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class BankStatementImportingService {
  
  private _Url: string = "/api/bankstatementimporting/";
  private _UrlOld: string = "/api/jccstatement/";
  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {
  }
  getAllPayments() {
    return this.authHttp.get(this._Url + "GetAllPayments" + "/");
  }
  getAllIgnoredPayments() {
    return this.authHttp.get(this._Url + "GetAllIgnoredPayments" + "/");
  }
  validatePolicies(bankStatement: object) {
    return this.authHttp.post<any>(this._Url + "ValidatePolicies" + "/", bankStatement);

  }
  importExcelData(selectedBank, excelData) {
    return this.authHttp.post<any>(this._Url + "ImportExcelData" + "/" + selectedBank + "/", excelData);
  }
  paymentTransactionImporting(bank: string, bankStatement: object[]) {
    //console.log("FROM SERVICE ",bankStatement);
    return this.authHttp.post<any>(this._Url + "PaymentTransactionImporting/" + bank + "/", bankStatement);

  }
  validateByIdCard(idCard: string) {

    idCard = idCard.replace("/", "bs"); //when there is / on the customer id, convert to bs (backslash)
    return this.authHttp.post<any>(this._Url + "ValidateByIdCard/" + idCard.toString() + "/", []);
  }
  getPolicyExistingReceipts(policyNumber: string) {
    return this.authHttp.get<any>(this._Url + "GetPolicyExistingReceipts/" + policyNumber + "/");

  }

}

