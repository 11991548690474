import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NotificationsService,ProductsService, ToasterPosition, ToasterType } from '../../_services';
import { TranslateService } from '@ngx-translate/core';
export class ConfirmDialogModel {

  public title: string;
  public message: string;
  public type: string;
  public productType?: string;
  public CNApprovalIDs?: string;
  public productFormID?: number;
  public comment?: string;

  constructor(public atitle: string, public amessage: string, public aType?: string, public aProductType?: string, public aCNApprovalIDs?: string, public aProductFormID?: number, public aComment?: string) {

    this.title = atitle;
    this.message = amessage;
    this.type = aType;
    this.productType = aProductType;
    this.CNApprovalIDs = aCNApprovalIDs;
    this.productFormID = aProductFormID;
    this.comment = aComment;
  }
}


@Component({
  moduleId: module.id,
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  type: string;
  productType: string;
  productFormID: number;
  comment: string;
  videoSource: string;
  @ViewChild('commentInput', { static: false }) commentInput!: ElementRef;
  element: HTMLElement;

  pdfViewSource: string = "../../../assets/pdf/Extranet User Manual.pdf";

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;

  toggleVideo(event: any) {

    if (this.videoplayer.nativeElement.paused)
    {
      this.videoplayer.nativeElement.play();
    }
    else {
      this.videoplayer.nativeElement.pause();
    }
  }
  //comment: string = '';
  //maxLines: number = 3;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public productsService :ProductsService,
    public notificationService: NotificationsService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
 ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;
    this.productType = data.productType;
    this.productFormID = data.productFormID
    this.comment = data.comment;
    
    if (this.type == undefined) {
      this.type = '';
    }
  }


  proceedClick(type) {
    this.dialogRef.close(type);

  }

  responseClick(type) {
    this.dialogRef.close(type);

  }

  ngOnInit() {
  }

  //onConfirm(): void {
  //  // Close the dialog, return true
  //  this.dialogRef.close(true);
  //}

  //onDismiss(): void {
  //  // Close the dialog, return false
  //  this.dialogRef.close(false);
  //}

  videoGoTo(sec: number) {

    this.videoplayer.nativeElement.currentTime = sec;
    this.videoplayer.nativeElement.play();
  }

  saveCNcomment() {

    //if (this.commentInput.nativeElement.value.toString().trim() == "") {
    //  return;
    //}

    
    //console.log(this.commentInput.nativeElement.value);
    var commentList = { 'comment': this.commentInput.nativeElement.value }

    this.productsService.updateCoverNoteApproval('U', '-1', this.productFormID, 0, commentList).subscribe(data => {
      this.notificationService.show(ToasterType.success, "Comment saved successfully!!", ToasterPosition.topCenter, 3000);
    })
  }
}
/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
