
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ccworksheetsObject } from '../_models/ccworksheets';


@Injectable()
export class VersionModalService {
  private _Url: string = "/api/versionmodal/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "versionmodal";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }


  getAll() {
    return this.authHttp.get<any>(this._Url + 'GetAll/ALL/' );
  }


  getLatestVersion() {
    return this.authHttp.get<any>(this._Url + 'GetLatestVersion/VER/');
  }


  GetByID(id: number) {
    return this.authHttp.get<any>(this._Url + 'GetByID/ID/' + id + '/');
  }




}
