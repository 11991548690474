
import { BranchObject } from '../_models/branch';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { EmailDistributionObject } from '../_models/emaildistributionobject';


@Injectable()
export class SystemValuesService {
  private _Url: string = "/api/systemvalues/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }


  getAll() {
    return this.authHttp.get<any>(this._Url + "GetAll/" + "ALL" + "/");
  }


  getByID(aID: number) {
    //console.log("Test - Pieris: " + aID);
    return this.authHttp.get<any>(this._Url + "GetByID/" + "ID" + "/" + aID + "/");
  }


  getByValue(aValue: string) {
    return this.authHttp.get<any>(this._Url + "GetByValue/" + "VAL" + "/" + aValue + "/");
  }


  GetDBName() {
    return this.authHttp.get<any>(this._Url + "GetDBName/");
  }


  update(num: number) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/INC/", num);
  }

  //EMAIL DISTRIBUTION
  insertUpdateEmailDistribution(run_Code: string, id: number,aEmailDistributionObject: EmailDistributionObject) {
    return this.authHttp.post<any>(this._Url + "ModifyEmailDistribution/" + run_Code + "/" + id + "/", aEmailDistributionObject);
  }


  deleteEmailDistribution(aEmailDistributionID: number) {
    let aEmailDistributionObject: EmailDistributionObject = new EmailDistributionObject();
    return this.authHttp.post<any>(this._Url + "ModifyEmailDistribution/" + "D" + "/" + aEmailDistributionID, aEmailDistributionObject)
  }

  getALLEmailDistribution() {
    //console.log("Test - Pieris: " + aID);
    return this.authHttp.get<any>(this._Url + "ViewEmailDistribution/" + "ALL" + "/" + 'empty' + "/" + 0);
  }

  getByIDEmailDistribution(id: number) {
    //console.log("Test - Pieris: " + aID);
    return this.authHttp.get<any>(this._Url + "ViewEmailDistribution/" + "ID" + "/" + 'empty' + "/" + id);
  }

  getByInsuranceTypeEmailDistribution(insuranceType: string) {
    //console.log("Test - Pieris: " + aID);
    return this.authHttp.get<any>(this._Url + "ViewEmailDistribution/" + "ALL" + "/" + insuranceType + "/" + 0);
  }

}
