
export class EmailDistributionObject {

  InsuranceType: string = "";
  MajorCategory: number = 0;
  BranchID: number = 0;
  Emails: string = "";
  Module: string = "";
  Description: string = "";
  Status: string = "";


}
