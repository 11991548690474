export class ManualReceiptRegisterObject {
  manualReceiptRegisterID: number = 0;
  dateIn: Date = new Date();
  dateOut: Date = new Date();
  firstNumber: number = 0;
  lastNumber: number = 0;
  userID: number = 0;
  creationDate: Date = new Date();
  createdBy: number = 0;
  lastUpdateDate: Date = new Date();
  lastUpdatedby: Date = new Date();
  agentCode: string = "";
  user: any = 0;
  cartonNumber: number = 0;
  status: string = "A";

}
