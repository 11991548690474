import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if available

  
    let currentUser = this.authenticationService.currentUserValue;


    if (currentUser && currentUser.accessToken) {
      if (this.authenticationService.isTokenExpired) {
          //'console.log('Log out from jwt.interceptor.ts');
          this.authenticationService.logout();              
        //this.authenticationService.renew().subscribe();      //    
      } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.accessToken}`
        }
      });
      }
    }   
    return next.handle(request);
  }



}
