import { HttpHandler, HttpClient, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appInitializer } from './_helpers';
// -------------------------------------------------------------------------
//const cookieConfig: NgcCookieConsentConfig = {
//  cookie: {
//    domain: 'localhost' // it is recommended to set your domain, for cookies to work properly
//  },
//  position: 'bottom-right',
//  theme:'classic',
//  palette: {
//    popup: {
//      background: '#000'
//    },
//    button: {
//      background: '#f1d600'
//    }
//  },
//  type: 'opt-in',
//content: {
//message: 'This website uses cookies to ensure you get the best experience on our website.',
//dismiss: 'Got it!',
//deny: 'Refuse cookies',
//link: 'Learn more',
//href: 'https://www.ydrogios.com.cy/el/gdpr',
//policy: 'Cookie Policy'
//}
//};
export class NoCacheHeadersInterceptor {
    intercept(req, next) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                Pragma: 'no-cache'
            }
        });
        return next.handle(authReq);
    }
}
const ɵ0 = appInitializer, ɵ1 = JWT_OPTIONS;
export class AppModule {
}
export function httpTranslateLoader(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0, ɵ1 };
