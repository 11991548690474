import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class DashboardService {
  private _Url: string = "/api/dashboard/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }

  //graphs
  monthlyproductionGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "GraphProductionGetData/MONTHLY/" + yearInput + "/" + monthInput + "/");
  }


  cumulativeproductionGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "GraphProductionGetData/CUMULATIVE/" + yearInput + "/" + monthInput + "/");
  }

  cumulativeproductionGetDataFromTo(yearInput: number, monthInputFrom: number, monthInputTo: number) {
    return this.authHttp.get<any>(this._Url + "GraphProductionGetDataFromTo/CUMULATIVE/" + yearInput + "/" + monthInputFrom + "/" + monthInputTo +  "/");
  }


  monthlyproductionmajorGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "GraphProductionmajorGetData/MONTHLY/" + yearInput + "/" + monthInput + "/");
  }


  cumulativeproductionmajorGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "GraphProductionmajorGetData/CUMULATIVE/" + yearInput + "/" + monthInput + "/");
  }

  cumulativeproductionmajorGetDataFromTo( yearInput: number, monthInputFrom: number, monthInputTo: number) { // new graph (dashboard) 
    return this.authHttp.get<any>(this._Url + "GraphProductionmajorGetDataFromTo/CUMULATIVE/" + yearInput + "/" + monthInputFrom + "/" + monthInputTo + "/");
  }


  //for all year
  monthlyproductionmajorcurrentyearGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "MonthlyproductionmajorcurrentyearGetData/" + yearInput + "/" + monthInput + "/");
  }


  //cards
  yearlyinfoGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "YearlyinfoGetData/" + yearInput + "/" + monthInput + "/");
  }

  yearlyinfoclaimsGetData(yearInput: number, monthInput: number) {
    return this.authHttp.get<any>(this._Url + "YearlyinfoclaimsGetData/" + yearInput + "/" + monthInput + "/");
  }

  lossratioGetData(yearInput: number) {
    return this.authHttp.get<any>(this._Url + "LossratioGetData/" + yearInput + "/");
  }

  contestsGetData(year: number, fromPeriod: number, toPeriod: number) {
    return this.authHttp.get<any>(this._Url + "ContestsGetData/" + year + "/" + fromPeriod + "/" + toPeriod + "/");
  }

  contestsNewAgentsGetData(year: number, fromPeriod: number, toPeriod: number) {
    return this.authHttp.get<any>(this._Url + "ContestsNewAgentsGetData/" + year + "/" + fromPeriod + "/" + toPeriod + "/");
  }

  contestsAnetaXristougena(year: number, fromPeriod: number, toPeriod: number) {
    return this.authHttp.get<any>(this._Url + "ContestsAnetaXristougena/" + year + "/" + fromPeriod + "/" + toPeriod + "/");
  }

  getClientsWhoHasTheMobileApp() {
    return this.authHttp.get<any>(this._Url + "GetClientsWhoHasTheMobileApp/");
  }

  getAllActiveClients() {
    return this.authHttp.get<any>(this._Url + "GetAllActiveClients/");
  }

}
